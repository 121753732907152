<br>
<div class="static-page-container">
  <iframe width="100%" [height]="frame_height" frameBorder="0" src="https://www.youtube.com/embed/eWI_BtcDJu0"
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
  <h2>Our Story</h2>
  <p>
    Flexo is a one-stop solution that helps space seekers find flexible offices and coworking spaces that are best
    suited to their requirements. As a specialist platform for flexible workspaces, we offer a wide range of options to
    choose from and leverage our relationships to help secure the best deal. In addition, space seekers save big on
    brokerage costs as our service is absolutely free.
  </p><br>
  <h3>A small Start-Up with a Big Heart</h3>
  <p style="padding-bottom:  30px;">
    Founded in the pandemic of 2020, Flexo is rooted in the philosophy of giving back. We believe that one of the ways
    to repair and rebuild the economy is to give.
  </p>
  <div class="static-page-flex-container">
    <img src="assets/images/how_do_we_give_back.jpg" alt="">
    <div>
      <p class="static-page-sub-heading">How Do We Give Back?</p><br>
      <p>
        We understand the challenges faced by businesses and therefore provide our service free of cost for space
        seekers.
      </p>
      <p>The planet is as important to us as the people on it! We make the world a little greener and the families of
        farmers a little happier by planting a fruit tree for every long-term desk booked with us. You can learn more
        about our <a class="static-page-links" routerLink="/joy-of-giving">Joy Of Giving initiative here</a>.</p>
    </div>
  </div><br>
  <div class="static-page-flex-container-reverse">
    <div>
      <p class="static-page-sub-heading">Culture</p><br>
      <p>
        Happy teams lead to happy customers. We strive to create a workplace culture that empowers and celebrates
        diversity. We are inclusive and believe in equal opportunity irrespective of age, designation, race, religion,
        ethnicity, gender and sexual orientation.
      </p>
    </div>
    <img src="assets/images/Culture.jpg" alt="">
  </div><br>
  <div class="static-page-flex-container">
    <img src="assets/images/be_the_change.jpg" alt="">
    <div>
      <p class="static-page-sub-heading">Be the Change</p><br>
      <p>
        So when you choose to go ahead with Flexo, you choose to be part of a bigger change and help in more ways than
        you can imagine. A small step with Flexo can help create a meaningful ripple for a better, greener and more
        sustainable future.
      </p>
      <p><a class="static-page-links" routerLink="/contact-form">Find your Next Office with Flexo </a>.</p>
    </div>
  </div>
</div>


