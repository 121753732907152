<div class="listing-details-slider swiper-container">
  <div class="swiper-wrapper" *ngIf="isMobile">
   <div class="position-relative">
    <div class="gallery-half position-relative" (click)="openImagesDialog()">
      <div *ngIf="space_details !== undefined">
        <div class="gallery-banner">
          <div *ngIf="ribbon" class="ribbon-label-details" [style.backgroundColor]="ribbon_color">
            {{ribbon}}
          </div>
          <div *ngIf="space_details?.youtube_url !== undefined ">
            <div *ngIf="space_details?.youtube_url !== '' && space_details?.youtube_url !== null"
              class="responsive-container">
              <iframe class="responsive-iframe" [src]="space_details?.youtube_url" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <img (error)="onImageError($event,space_details?.imageAlt)"
            *ngIf="space_details?.youtube_url == '' || space_details?.youtube_url === undefined" [src]="
          space_details?.images[0]
          ? 
          space_details?.images[0]
          : 'assets/images/details_placeholder_image.jpg'
          "
            alt="{{space_details?.imageAlt}} {{space_details?.images[0] | slice:(space_details?.images[0]?.lastIndexOf('/') + 1) }}" />
        </div>
      </div>
    </div>
    <div class="gallry_side_img ">
      <div class="gallery-one-third" (click)="openImagesDialog()">
        <div class="gallery-banner">
          <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
              space_details?.images[space_details?.youtube_url ? 0 : 1]
                ? 
                  space_details?.images[space_details?.youtube_url ? 0 : 1]
                : 'assets/images/details_placeholder_image.jpg'
            "
            alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 0 : 1] | slice:(space_details?.images[space_details?.youtube_url ? 0 : 1]?.lastIndexOf('/') + 1) }}" />
        </div>
        <div class="gallery-banner">
          <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
              space_details?.images[space_details?.youtube_url ? 2 : 3]
                ? 
                  space_details?.images[space_details?.youtube_url ? 2 : 3]
                : 'assets/images/details_placeholder_image.jpg'
            "
            alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 2 : 3] | slice:(space_details?.images[space_details?.youtube_url ? 2 : 3]?.lastIndexOf('/') + 1) }}" />
        </div>
      </div>
      <div class="gallery-one-third" (click)="openImagesDialog()">
        <div class="gallery-banner">
          <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
              space_details?.images[space_details?.youtube_url ? 1 : 2]
                ? 
                  space_details?.images[space_details?.youtube_url ? 1 : 2]
                : 'assets/images/details_placeholder_image.jpg'
            "
            alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 1 : 2] | slice:(space_details?.images[space_details?.youtube_url ? 1 : 2]?.lastIndexOf('/') + 1) }}" />
        </div>
        <div class="gallery-banner">
          <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
              space_details?.images[space_details?.youtube_url ? 3 : 4]
                ? 
                  space_details?.images[space_details?.youtube_url ? 3 : 4] : 'assets/images/details_placeholder_image.jpg'
            "
            alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 3 : 4] | slice:(space_details?.images[space_details?.youtube_url ? 3 : 4]?.lastIndexOf('/') + 1) }}" />
        </div>
      </div>
    </div>
    <button class="btn btn-white-view-more" (click)="openImagesDialog()" type="button">
      View More
    </button>
   </div>
  </div>
  <div class="gallery" *ngIf="!isMobile">
    <button class="btn btn-white-view-more" (click)="openImagesDialog()" type="button">
      View More
    </button>
    <div class="gallery-half position-relative" (click)="openImagesDialog()">
      <div *ngIf="space_details !== undefined">
        <div class="gallery-banner">
          <div *ngIf="ribbon" class="ribbon-label-details" [style.backgroundColor]="ribbon_color">
            {{ribbon}}
          </div>
          <div *ngIf="space_details?.youtube_url !== undefined ">
            <div *ngIf="space_details?.youtube_url !== '' && space_details?.youtube_url !== null"
              class="responsive-container">
              <iframe class="responsive-iframe" [src]="space_details?.youtube_url" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <img (error)="onImageError($event,space_details?.imageAlt)"
            *ngIf="space_details?.youtube_url == '' || space_details?.youtube_url === undefined" [src]="
          space_details?.images[0]
          ? 
          space_details?.images[0]
          : 'assets/images/details_placeholder_image.jpg'
          "
            alt="{{space_details?.imageAlt}} {{space_details?.images[0] | slice:(space_details?.images[0]?.lastIndexOf('/') + 1) }}" />
        </div>
      </div>
    </div>
    <div class="gallery-one-third" (click)="openImagesDialog()">
      <div class="gallery-banner">
        <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
            space_details?.images[space_details?.youtube_url ? 0 : 1]
              ? 
                space_details?.images[space_details?.youtube_url ? 0 : 1]
              : 'assets/images/details_placeholder_image.jpg'
          "
          alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 0 : 1] | slice:(space_details?.images[space_details?.youtube_url ? 0 : 1]?.lastIndexOf('/') + 1) }}" />
      </div>
      <div class="gallery-banner">
        <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
            space_details?.images[space_details?.youtube_url ? 2 : 3]
              ? 
                space_details?.images[space_details?.youtube_url ? 2 : 3]
              : 'assets/images/details_placeholder_image.jpg'
          "
          alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 2 : 3] | slice:(space_details?.images[space_details?.youtube_url ? 2 : 3]?.lastIndexOf('/') + 1) }}" />
      </div>
    </div>
    <div class="gallery-one-third" (click)="openImagesDialog()">
      <div class="gallery-banner">
        <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
            space_details?.images[space_details?.youtube_url ? 1 : 2]
              ? 
                space_details?.images[space_details?.youtube_url ? 1 : 2]
              : 'assets/images/details_placeholder_image.jpg'
          "
          alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 1 : 2] | slice:(space_details?.images[space_details?.youtube_url ? 1 : 2]?.lastIndexOf('/') + 1) }}" />
      </div>
      <div class="gallery-banner">
        <img (error)="onImageError($event,space_details?.imageAlt)" [src]="
            space_details?.images[space_details?.youtube_url ? 3 : 4]
              ? 
                space_details?.images[space_details?.youtube_url ? 3 : 4] : 'assets/images/details_placeholder_image.jpg'
          "
          alt="{{space_details?.imageAlt}} {{space_details?.images[space_details?.youtube_url ? 3 : 4] | slice:(space_details?.images[space_details?.youtube_url ? 3 : 4]?.lastIndexOf('/') + 1) }}" />
      </div>
    </div>
  </div>
  <div class="shortlist-share-container">
    <div class="save-btn" style="margin-right: 10px">
      <!-- <span class="btn v3"
     (click)="shortList(); is_shortlisted = (is_shortlisted == false) ? true : false"><i class="icofont-heart"></i></span> -->
      <a (click)="updateShortList()" class="btn" [style.color]="is_shortlisted ? '#F76900' : '#F76900'">
        <!-- <i class="ion-favorite on-image-icon"></i> -->
        <i [ngClass]="is_shortlisted ? 'fa fa-heart color-on-heart' : 'fa fa-heart-o'" aria-hidden="true"></i>
      </a>
    </div>
    <div class="share-btn">
      <a class="btn"><i class="ion-android-share-alt" style="bottom: 3px"></i></a>
      <ul class="social-share">
        <li class="bg-fb" (click)="openFacebook()">
          <a class="share-button"><i class="ion-social-facebook"></i></a>
        </li>
        <li class="bg-tt" (click)="openLinkedIn()">
          <a class="share-button"><i class="ion-social-linkedin"></i></a>
        </li>
        <li class="bg-ig" (click)="openWhatsapp()">
          <a class="share-button"><i class="ion-social-whatsapp"></i></a>
        </li>
        <li
          style=" background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);"
          (click)="openInstagram()">
          <a class="share-button"><i class="ion-social-instagram"></i></a>
        </li>
        <li style="background: white;" (click)="copyLink()">
          <a class="share-button"><i class="ion-ios-copy"></i></a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="listing-details-next slider-btn v4" (click)="next()"><i class="ion-arrow-right-c"></i></div>
  <div class="listing-details-prev slider-btn v4" (click)="prev()"><i class="ion-arrow-left-c"></i></div> -->
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="list-details-title v2">
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="single-listing-title">
              <!-- is_go_back_visible -->
              <p *ngIf="false" style=" 
                  display: flex;
                  color: gray;
                  cursor: pointer;
                  align-items: center;
                  margin-bottom: 13px;
                " (click)="goBack()">
                <i class="material-icons" style="
                    color: gray !important;
                    font-size: 17px;
                    position: relative;
                    right: 2px;
                  ">keyboard_arrow_left</i><span style="text-decoration: underline">Go back</span>
              </p>

              <div class="bredcrumb_sec">
                <nav>
                  <ol class="breadcrumb bg-white p-0 mb-0">
                    <!-- <li class="breadcrumb-item"><a routerLink="/">Home</a></li> -->
                    <li *ngIf="spaceType != 'coworking café restaurant'" style="text-transform: capitalize;" class="breadcrumb-item"><a routerLink="/in/{{this.spaceType?.replace(' ', '-')}}/{{this.city_param?.toLowerCase()}}">{{space_details?.spaceType}} in {{space_details?.contact_city_name}}</a></li>
                    <li *ngIf="spaceType == 'coworking café restaurant'" style="text-transform: capitalize;" class="breadcrumb-item"><a [routerLink]="['/in', formatUrl(spaceType), city_param?.toLowerCase()]">
                      {{ space_details?.spaceType }} in {{ space_details?.contact_city_name }}
                    </a></li>
                    <!-- <li style="text-transform: capitalize;" class="breadcrumb-item " aria-current="page"><a
                        routerLink="/in/spaces/{{space_details?.contact_city_name}}">{{
                        space_details?.contact_city_name}}</a></li> -->
                    <li *ngIf="spaceType != 'coworking café restaurant'" style="text-transform: capitalize;" class="breadcrumb-item" aria-current="page"><a routerLink="/in/{{this.spaceType?.replace(' ', '-')}}/{{this.city_param?.toLowerCase()}}/{{space_details?.location_name?.replace(' ', '-')?.toLowerCase()}}">{{
                        space_details?.location_name}}</a>
                    </li>
                    <li *ngIf="spaceType == 'coworking café restaurant'" style="text-transform: capitalize;" class="breadcrumb-item" aria-current="page"><a [routerLink]="['/in', formatUrl(spaceType), city_param?.toLowerCase(), space_details?.location_name?.replace(' ', '-')?.toLowerCase()]">{{
                        space_details?.location_name}}</a>
                    </li>
                    <!-- <li style="text-transform: capitalize;" *ngIf="isLongterm || isShortterm" class="breadcrumb-item"
                      aria-current="page">{{
                      space_details?.spaceTitle}}</li>
                    <li style="text-transform: capitalize;" *ngIf="isCoworking" class="breadcrumb-item"
                      aria-current="page">{{space_details?.actual_name}}
                    </li> -->
                  </ol>
                </nav>
              </div>

              <h2 *ngIf="isLongterm || isShortterm" class="detail_head">
                {{ space_details?.spaceTitle }}
              </h2>

              <h1 *ngIf="isCoworking" style="text-transform: capitalize;">
                {{ space_details?.actual_name }} {{ space_details?.location_name }}
              </h1>

              <p style="text-transform: capitalize;">
                <i class="ion-ios-location"></i>
                {{ space_details?.location_name }}
                <!-- mumbai -->
              </p>
              <!-- <div class="rating-summary" style="display: flex">
                <p style="
                    margin-right: 10px;
                    font-size: 11px;
                    align-self: center;
                  ">
                  Space Category
                </p>
                <div class="rating-result" title="60%" *ngIf="space_details?.rating">
                  <ul class="product-rating">
                    <li *ngFor="let element of rating_array; index as erai" [innerHTML]="showStarIcon(erai, space_details?.rating)"></li>
                   
                  </ul>
                </div>
                <div class="rating-result" title="60%" *ngIf="!space_details?.rating">
                  <ul class="product-rating">
                    <li><i class="ion-android-star-outline"></i></li>
                    <li>&nbsp;</li>
                    <li>
                      <p>Unrated</p>
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- <div class="single-listing-title"> -->
              <div class="rating-summary">
                <div class="rating-like">
                  <div class="rating-info">
                    <div class="rateing-c rating-info-single gap-2"
                      *ngIf="!isLongterm && reviews.length > 0 && space_details?.ratingsAvg > 0">
                      <div class="d-flex align-items-center">
                        <ul class="product-rating-c">
                          <li class>
                            <i style="cursor: context-menu;"
                              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 1, 'fa-star-half-o': space_details?.ratingsAvg > 0 && space_details?.ratingsAvg < 1, 'fa-star-o': space_details?.ratingsAvg <= 0}"
                              aria-hidden="true"></i>
                          </li>
                          <li class>
                            <i style="cursor: context-menu;"
                              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 2, 'fa-star-half-o': space_details?.ratingsAvg > 1 && space_details?.ratingsAvg < 2, 'fa-star-o': space_details?.ratingsAvg <= 1}"
                              aria-hidden="true"></i>
                          </li>
                          <li class>
                            <i style="cursor: context-menu;"
                              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 3, 'fa-star-half-o': space_details?.ratingsAvg > 2 && space_details?.ratingsAvg < 3, 'fa-star-o': space_details?.ratingsAvg <= 2}"
                              aria-hidden="true"></i>
                          </li>
                          <li class>
                            <i style="cursor: context-menu;"
                              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 4, 'fa-star-half-o': space_details?.ratingsAvg > 3 && space_details?.ratingsAvg < 4, 'fa-star-o': space_details?.ratingsAvg <= 3}"
                              aria-hidden="true"></i>
                          </li>
                          <li class>
                            <i style="cursor: context-menu;"
                              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 5, 'fa-star-half-o': space_details?.ratingsAvg > 4 && space_details?.ratingsAvg < 5, 'fa-star-o': space_details?.ratingsAvg <= 4}"
                              aria-hidden="true"></i>
                          </li>
                        </ul>
  
                        <div class="rating-number">
                          <p> {{space_details?.ratingsAvg || 0}} </p>
                        </div>
                      </div>

                      <div class="rating-info-single">
                        <a style="color: #646464 !important;" class="client-review rating-info-single ml-0">
                          {{reviews?.length || 0}} reviews </a>
                      </div>
                    </div>
                    <div *ngIf="space_details?.ratingsAvg == 0 && !isLongterm" class="total-viewer pr-2">
                      <!-- <div class="review-heading h3 text-dark">Reviews</div> -->
                      <p style="margin:0;color:#646464" *ngIf="reviews?.length > 0">No review yet</p>
                    </div>
                    <div class="d-flex align-items-center gap-2 reviewInnerClass">
                      <div class="total-viewer rating-info-single" *ngIf="!isLongterm">
                        <i class="fa fa-user-o" aria-hidden="true"></i>
                        <p>{{space_details?.howManyPeopleInYourSpace}} people</p>
                      </div>
                      <div class="total-viewer rating-info-single" *ngIf="isLongterm">
                        <i class="fa fa-building-o" aria-hidden="true"></i>
                        <p>{{space_details?.spaceStatus || 'N/A'}}</p>
                      </div>
                      <div class="watch-time rating-info-single" *ngIf="isShortterm">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p>{{space_details?.minimum_hours/60}} hrs min</p>
                      </div>
                      <div class="sq-ft rating-info-single">
                        <img src="assets/images/scale.svg" alt class="sq-ft-icon">
                        <p>{{space_details?.spacesqft}} sqft</p>
                      </div>
                    </div>
                  </div>
                  <div class="like-dislike-c" *ngIf="!isLongterm">
                    <a class="product-like-c">
                      <i (click)="!isLoading && $event.stopPropagation(); voteDevoteSite('upvote');" class="fa"
                        [ngClass]="{'fa-thumbs-up orange-color': existingUpVote == 1, 'fa-thumbs-o-up black-color': existingUpVote != 1 }"
                        [class.disabled]="isLoading" aria-hidden="true"></i>
                      <span *ngIf="space_details?.upvote > 0">{{space_details?.upvote}}</span>
                      <span *ngIf="space_details?.upvote <= 0">0</span>
                    </a>

                    <a class="product-dis-like-c">
                      <i class="fa fa-thumbs-o-down" [class.disabled]="isLoading"
                        (click)="!isLoading && $event.stopPropagation();voteDevoteSite('downvote')"
                        [ngClass]="existingDownVote == 1 ? 'fa fa-thumbs-down orange-color':'fa fa-thumbs-o-down'"
                        [ariaDisabled]="isLoading" aria-hidden="true">
                      </i>
                      <!-- <span>{{space_details?.downvote}}</span> -->
                    </a>
                  </div>
                  <!-- <a href="" class="fav-icon-c">
                            <i class="fa fa-heart" aria-hidden="true"></i>
                        </a> -->
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-5 col-sm-12"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Listing Details Hero ends-->
<!--Listing Details Info starts-->
<div class="section-padding" style="padding-top: 0px">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 order-lg-0 order-md-1 order-1">
        <div id="list-menu" class="list_menu d-md-block d-none">
          <ul class="list-details-tab fixed-nav list-details-tab-item">
            <li class="nav-item" [ngClass]="{ active: list_tab == 1 }">
              <a (click)="scrollToSection('overview')" [ngClass]="{ active: list_tab == 1 }">About the Space</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: list_tab == 2 }" *ngIf="isCoworking || isLongterm">
              <a (click)="scrollToSection('price')" [ngClass]="{ active: list_tab == 2 }">Pricing</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: list_tab == 3 }">
              <a (click)="scrollToSection('location')" [ngClass]="{ active: list_tab == 3 }">Location</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: list_tab == 5 }" *ngIf="!isLongterm">
              <a (click)="scrollToSection('Business')" [ngClass]="{ active: list_tab == 3 }">Business Hours</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: list_tab == 6 }" *ngIf="!isLongterm && !isCoworking">
              <a (click)="scrollToSection('Cancellation_Policy')" [ngClass]="{ active: list_tab == 3 }">Cancellation
                Policy</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: list_tab == 4 }">
              <a (click)="scrollToSection('review_rating')" [ngClass]="{ active: list_tab == 4 }">Reviews</a>
            </li>
          </ul>
        </div>
        <!--Listing Details starts-->
        <div class="list-details-wrap">
          <div id="overview" class="list-details-section">
            <h2 style="font-size:20px">About the Space</h2>
            <div class="overview-content">
              <h3 class="mar-bot-10">
                {{ space_details?.about }}
              </h3>

            </div>
            <div class="over-view-drop">
              <mat-accordion>
                <mat-expansion-panel *ngIf="space_details?.parkingDescription">
                  <mat-expansion-panel-header>
                    <!-- <mat-panel-title>
                    Personal data
                  </mat-panel-title>
                  <mat-panel-description>
                    Type your name and age
                  </mat-panel-description> -->
                    <div class="over-view-drop-heading">
                      <div class="over-view-drop-heading-logo-grp">
                        <img src="assets/images/parking-icon.png" alt class="over-view-drop-heading-icon">
                        <h2 class="fs-18 m-0"> Parking</h2>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="over-view-drop-content">
                    <div class="pb-4">
                      <div class="font-weight-bold pb-1" style="font-size: 17px;">Parking options</div>
                      <h3 class="operational" style="text-transform: capitalize;">
                        {{parkingOptionsString}}
                      </h3>
                    </div>
                    <div class="pb-4">
                      <div class="font-weight-bold pb-1" style="font-size: 17px;">Parking description</div>
                      <h3 class="operational">
                        {{space_details?.parkingDescription}}
                      </h3>
                    </div>
                  </div>
                  <!-- <mat-form-field>
                  <input matInput placeholder="First name">
                </mat-form-field>
            
                <mat-form-field>
                  <input matInput placeholder="Age">
                </mat-form-field> -->
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="space_details?.lightingDescription && !isCoworking">
                  <mat-expansion-panel-header>

                    <div class="over-view-drop-heading">
                      <div class="over-view-drop-heading-logo-grp">
                        <img src="assets/images/lighting-icon.png" alt class="over-view-drop-heading-icon">
                        <h2 class="fs-18 m-0"> Lighting</h2>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="over-view-drop-content">
                    <h3 class="operational">
                      {{space_details?.lightingDescription}}
                    </h3>
                  </div>

                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="space_details?.soundDescription && !isCoworking">
                  <mat-expansion-panel-header>

                    <div class="over-view-drop-heading">
                      <div class="over-view-drop-heading-logo-grp">
                        <img src="assets/images/sound-icon.png" alt class="over-view-drop-heading-icon">
                        <h2 class="fs-18 m-0"> Sound</h2>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="over-view-drop-content">
                    <h3 class="operational">
                      {{space_details?.soundDescription}}
                    </h3>
                  </div>

                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="space_details?.hostRulesDescription">
                  <mat-expansion-panel-header>

                    <div class="over-view-drop-heading">
                      <div class="over-view-drop-heading-logo-grp">
                        <img src="assets/images/host-rule-icon.png" alt class="over-view-drop-heading-icon">
                        <h2 class="fs-18 m-0"> Host rules</h2>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="over-view-drop-content">
                    <h3 class="operational">
                      {{space_details?.hostRulesDescription}}
                    </h3>
                  </div>

                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                  <!-- <p>I'm visible because I am open</p> -->
                </mat-expansion-panel>
              </mat-accordion>

            </div>
            <div class="mar-top-20" *ngIf="space_details?.facilities.length > 0">
              <h2 class="fs-20">Amenities</h2>
              <ul class="listing-features" *ngIf="!isMobile">
                <li *ngFor="let element of space_details?.facilities">
                  <span class="d-inline-block mr-1 font-size-md">
                    <i class="material-icons" style="font-size: 16px">{{
                      facilities[element.id]
                      }}</i>
                    <h3 style="font-size: 15px">{{ element.name }}</h3>
                  </span>
                  <!-- <span class="text-dark mr-5">
                  </span> -->
                </li>
              </ul>
              <div *ngIf="isMobile">
                <ul class="listing-features" *ngIf="space_details?.facilities.length <= 3">
                  <li *ngFor="let element of space_details?.facilities">
                    <span class="d-inline-block mr-1 font-size-md">
                      <i class="material-icons" style="font-size: 16px">{{
                        facilities[element.id]
                        }}</i>
                    </span>
                    <span class="text-dark mr-5">
                      {{ element.name }}
                    </span>
                  </li>
                </ul>
                <ul class="listing-features" *ngIf="space_details?.facilities.length > 3">
                  <li *ngFor="let element of show_less_facilities">
                    <span class="d-inline-block mr-1 font-size-md">
                      <i class="material-icons" style="font-size: 16px">{{
                        facilities[element.id]
                        }}</i>
                    </span>
                    <span class="text-dark mr-5">
                      {{ element.name }}
                    </span>
                  </li>
                </ul>
                <br />
                <a class="read-more" (click)="alterDescriptionText()" *ngIf="space_details?.facilities.length > 3">
                  <div style="color: #3e80d8">
                    <b>{{ showShortDesciption ? "Show more" : "Show less" }}</b>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div id="price" class="list-details-section" *ngIf="isCoworking">
            <h2 class="fs-20">Pricing</h2>
            <ul class="plan-list" *ngIf="space_details">
              <div *ngFor="let resource of resources_list">
                <li
                  *ngIf="space_details[resource.column_name] != null && space_details[resource.column_name] >0 && resource?.id != 8">
                  <div class="pricing_list">
                    <div>
                      <h4 class="text-capitalize">{{ resource.name }}</h4>
                      <p class="m-0">{{ resource.desc }}</p>

                    </div>
                    <div class="action-box col-lg-4 col-md-5 col-12 pl-md-2 pl-0">
                      <div class="price ml-md-2 ml-0" *ngIf="space_details[resource.column_name]">
                        <span>from</span>
                        <div style="
                          display: flex;
                        ">
                          <i class="icon-rupee"></i>
                          <h2 style="font-size: 18px">
                            <img style="height: 15px;" src="assets/images/rs-icon.png" alt=""> {{
                            space_details[resource.column_name]|| '' }}
                          </h2>
                          <small><span *ngIf="resource?.id !== 5 && resource?.id !== 6 && resource?.id !== 7">/seat</span>/{{resource?.id == 6 || resource?.id == 7? "hour": "month"}}</small>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="enqry_btn mt-2">
                    <a class="custum-btn blu-btn mt-0" (click)="openInquiryPopUp2(resource.name)">
                      Enquire Now
                    </a>
                  </div>
                </li>
                <li *ngIf="resource?.id == 8 && space_details?.originalPrice > 0">
                  <div class="pricing_list">
                    <div>
                      <h4 class="text-capitalize">{{ resource.name }}</h4>
                      <p>{{ resource.desc }}</p>

                    </div>
                    <div class="action-box col-lg-4 col-md-5 col-12 d-flex pl-md-2 pl-0">
                      <div class="price ml-md-2 ml-0" *ngIf="space_details[resource.column_name]">
                        <span>from</span>
                        <div style="
                          display: flex;
                          /* gap: 2px; */
                          align-items: baseline;
                        ">
                          <i class="icon-rupee"></i>
                          <h2 style="font-size: 18px">
                            <img style="height: 15px;" src="assets/images/rs-icon.png" alt=""> {{
                            space_details[resource.column_name]|| '' }}
                          </h2>
                          <small>/seat/{{
                            resource?.id == 6 || resource?.id == 7
                            ? "hour"
                            : "day"
                            }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="enqry_btn mt-2">
                    <a (click)="openRequetBuyPassPopup()" class="custum-btn" style="border-color: #4b975f;
                  background: #4b975f;">
                      Buy pass
                    </a>
                  </div>
                </li>
              </div>
            </ul>
          </div>

          <div id="location" class="list-details-section">
            <h2 class="location-section fs-20">Location</h2>
            <google-map height="400px" width="100%" [zoom]="15" [center]="marker?.position">
              <!-- <map-circle [center]="marker?.position" [radius]="circleRadius1" [options]="circleOptions1"></map-circle> -->
              <map-circle [center]="marker?.position" [radius]="circleRadius2" [options]="circleOptions2"></map-circle>
            </google-map><br />
            <div class="commute-details" style="justify-content: flex-end">
              <div class="commute-details-item">
                <div class="commute-label">
                  <i class="material-icons">subway</i>
                  <h3>Metro</h3>
                </div>
                <div class="commute-value">
                  <h3>{{ space_details?.near_by_metro || "NA" }}</h3>
                  <h3>
                    {{
                    space_details?.metro_distance
                    ? space_details?.metro_distance + " kms"
                    : "NA"
                    }}
                  </h3>
                </div>
              </div>
              <div class="commute-details-item">
                <div class="commute-label">
                  <i class="material-icons">train</i>
                  <h3>Railway</h3>
                </div>
                <div class="commute-value">
                  <h3>{{ space_details?.near_by_railway_name || "NA" }}</h3>
                  <h3>
                    {{
                    space_details?.railway_distance
                    ? space_details?.railway_distance + " kms"
                    : "NA"
                    }}
                  </h3>
                </div>
              </div>
            </div>
            <!--/.Carousel Wrapper-->
          </div>

          <div id="timings" class="list-details-section" id="Business" *ngIf="!isLongterm">
            <h2 class="fs-20">Business Hours</h2>
            <div class="business-hour-subtitle" *ngIf="space_details?.has_247_access">
              <img src="assets/images/business-hour-subtitle-icon.png" alt class="business-hour-subtitle-icon">
              <h3 class="operational">This space is operational <span> 24x7 </span></h3>
            </div>
            <ul class="business-hours">
              <li class="business-open">
                <h3>
                  <span class="day">Monday</span>
                </h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[0]?.isClosed && mon_opening_time && mon_closing_time">
                    <span class="time">{{mon_opening_time}}</span>
                    -
                    <span class="time">{{mon_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[0]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Tuesday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[1]?.isClosed && tue_opening_time && tue_closing_time">
                    <span class="time">{{tue_opening_time}}</span>
                    -
                    <span class="time">{{tue_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[1]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Wednesday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[2]?.isClosed && wed_opening_time && wed_closing_time">
                    <span class="time">{{wed_opening_time}}</span>
                    -
                    <span class="time">{{wed_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[2]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Thursday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf=" workingTime?.length && !workingTime[3]?.isClosed && thu_opening_time && thu_closing_time">
                    <span class="time">{{thu_opening_time}}</span>
                    -
                    <span class="time">{{thu_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[3]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Friday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[4]?.isClosed && fri_opening_time && fri_closing_time">
                    <span class="time">{{fri_opening_time}}</span>
                    -
                    <span class="time">{{fri_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[4]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Saturday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[5]?.isClosed && sat_opening_time && sat_closing_time">
                    <span class="time">{{sat_opening_time}}</span>
                    -
                    <span class="time">{{sat_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[5]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
              <li class="business-open">
                <h3><span class="day">Sunday</span></h3>
                <h3 class="atbd_open_close_time">
                  <span
                    *ngIf="workingTime?.length && !workingTime[6]?.isClosed && sun_opening_time && sun_closing_time">
                    <span class="time">{{sun_opening_time}}</span>
                    -
                    <span class="time">{{sun_closing_time}}</span>
                  </span>
                  <span *ngIf="workingTime?.length && workingTime[6]?.isClosed" class="trend-closed">
                    <span class="time">Closed</span>
                  </span>
                </h3>
              </li>
            </ul>
          </div>
          <div class="list-details-section" *ngIf="serviceArray?.length > 0 && !isLongterm && !isCoworking">
            <div class="add-ons">
              <div class="add-ons-heading">
                <h2 class="fs-20">Add-ons from the host</h2>
                <h3 class="operational">Host provided services, items or options. Available at
                  checkout.</h3>
              </div>

              <div class="add-ons-all list-details-section-content-c">
                <div class="add-ons-single" *ngFor="let item of serviceArray">
                  <!-- <div class="add-ons-single-img" *ngIf="item?.serviceName == 'Professional Photographer'">
                    <img src="assets/images/add-on-img-1.jpg" alt>
                  </div>
                  <div class="add-ons-single-img" *ngIf="item?.serviceName == 'Photography Equipment'">
                    <img src="assets/images/camera.png" alt>
                  </div>
                  <div class="add-ons-single-img" *ngIf="item?.serviceName == 'Speaker'">
                    <img src="assets/images/speaker.png" alt>
                  </div> -->
                  <div class="add-ons-single-text">
                    <h3 class="add-ons-single-text-heading fs-16">
                      {{item?.serviceName}}</h3>
                    <p> <img src="assets/images/rupee-icon.png" alt class="rupee-icon"> <span>
                        {{item?.servicePrice}}/{{item?.servicePriceType}} </span></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="list-details-section" id="Cancellation_Policy" *ngIf="!isLongterm && !isCoworking">
            <div class="cancel-policy-c">
              <div class="cancel-policy-heading">
                <h2 class="fs-20">Cancellation Policy</h2>
              </div>
              <div class="cancel-policy-content list-details-section-content-c">
                <h3 class="fs-18">{{space_details?.cancellationPolicy}}</h3>
                <!-- <p>{{space_details?.cancellationPolicyDescription}}</p> -->
                <h3 class="operational" [innerHtml]="space_details?.cancellationPolicyDescription"></h3>
                <!-- <a href class="read-more-btn"> Read More </a>
                <p>All Bookings are subject to Peerspace’s Grace Period policy
                  which provides a full refund for Bookings cancelled within 24
                  hours from receipt of a Booking Confirmation but no later than
                  48 hours prior to the Event start time.</p> -->
              </div>

            </div>

          </div>

          <div id="review_rating" class="list-details-section">
            <div class="review-section">

              <div class="review-section-heading">
                <h2 class="fs-20 m-0">Reviews & Ratings <span *ngIf="reviews?.length > 0">({{reviews?.length}})</span>
                </h2>
                <a (click)="openAddReviewDialog()" class="custum-btn outline-orng-btn"> <i class="fa fa-pencil"
                    aria-hidden="true"></i> Leave a Review</a>
              </div>
              <div class="row align-items-center mx-0" *ngIf="space_details?.ratingsAvg?.length > 0">
                <ul class="product-rating-c d-flex">
                  <li class style="color: #F76900; margin-right: 5px;">
                    <i style="cursor: context-menu;"
                      [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 1, 'fa-star-half-o': space_details?.ratingsAvg > 0 && space_details?.ratingsAvg < 1, 'fa-star-o': space_details?.ratingsAvg <= 0}"
                      aria-hidden="true"></i>
                  </li>
                  <li class style="color: #F76900; margin-right: 5px;">
                    <i style="cursor: context-menu;"
                      [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 2, 'fa-star-half-o': space_details?.ratingsAvg > 1 && space_details?.ratingsAvg < 2, 'fa-star-o': space_details?.ratingsAvg <= 1}"
                      aria-hidden="true"></i>
                  </li>
                  <li class style="color: #F76900; margin-right: 5px;">
                    <i style="cursor: context-menu;"
                      [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 3, 'fa-star-half-o': space_details?.ratingsAvg > 2 && space_details?.ratingsAvg < 3, 'fa-star-o': space_details?.ratingsAvg <= 2}"
                      aria-hidden="true"></i>
                  </li>
                  <li class style="color: #F76900; margin-right: 5px;">
                    <i style="cursor: context-menu;"
                      [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 4, 'fa-star-half-o': space_details?.ratingsAvg > 3 && space_details?.ratingsAvg < 4, 'fa-star-o': space_details?.ratingsAvg <= 3}"
                      aria-hidden="true"></i>
                  </li>
                  <li class style="color: #F76900; margin-right: 5px;">
                    <i style="cursor: context-menu;"
                      [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 5, 'fa-star-half-o': space_details?.ratingsAvg > 4 && space_details?.ratingsAvg < 5, 'fa-star-o': space_details?.ratingsAvg <= 4}"
                      aria-hidden="true"></i>
                  </li>
                </ul>

                <div class="rating-number">
                  <p> {{space_details?.ratingsAvg}} </p>
                </div>
              </div>

              <div class="review-section-single" *ngFor="let srrl of spaceRatingReviewList">
                <div class="review-section-single-img">
                  <img [src]="srrl?.profile_image ? srrl?.profile_image : 'assets/images/user_image.png'" alt>
                </div>
                <div class="review-section-single-cont">
                  <h5 style="text-transform: capitalize;">{{srrl?.userName}}</h5>
                  <div class="book-again-star">
                    <ul class="product-rating-c">
                      <li class>
                        <i [ngClass]="srrl?.rating>=1?'fa fa-star':'fa fa-star-o'" aria-hidden="true"></i>
                      </li>
                      <li class>
                        <i [ngClass]="srrl?.rating>=2?'fa fa-star':'fa fa-star-o'" aria-hidden="true"></i>
                      </li>
                      <li class>
                        <i [ngClass]="srrl?.rating>=3?'fa fa-star':'fa fa-star-o'" aria-hidden="true"></i>
                      </li>
                      <li class>
                        <i [ngClass]="srrl?.rating>=4?'fa fa-star':'fa fa-star-o'" aria-hidden="true"></i>
                      </li>
                      <li class>
                        <i [ngClass]="srrl?.rating>=5?'fa fa-star':'fa fa-star-o'" aria-hidden="true"></i>
                      </li>
                    </ul>
                    <ul class="book-again" *ngIf="srrl?.book_again == 1">
                      <li>
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </li>
                      <li>
                        Yes I would book again
                      </li>
                    </ul>
                  </div>
                  <p>{{srrl?.Review}}</p>
                  <p class="review-section-single-cont-date">{{ srrl.createdAt | date: 'MMMM d, y' }}</p>



                </div>
                <!-- <div class="review-section-single" >
                    <div class="review-section-single-img">
                        <img src="assets/images/add-on-img-1.jpg" alt="">
                    </div>
                    <div class="review-section-single-cont">
                       <h5>Madison G.</h5>
                       <div class="book-again-star">
                        <ul class="product-rating-c">
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star-o" aria-hidden="true"></i>
                          </li>
                        </ul>
                        <ul class="book-again">
                          <li>
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </li>
                          <li>
                            Yes I would book again
                          </li>
                        </ul>
                     </div>  
                        <p>It's the details that make Dummy such a winner. By way of comparison, consider last summer's "My Big Fat Greek Wedding," in which each actor put a heartfelt spin on his or her one-joke character.</p>
                        <p class="review-section-single-cont-date">December 31, 2023</p>
                    </div>
            
                </div>
                <div class="review-section-single" >
                    <div class="review-section-single-img">
                        <img src="assets/images/add-on-img-1.jpg" alt="">
                    </div>
                    <div class="review-section-single-cont">
                       <h5>Madison G.</h5>
                       <div class="book-again-star">
                        <ul class="product-rating-c">
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li class="">
                              <i class="fa fa-star-o" aria-hidden="true"></i>
                          </li>
                        </ul>
                        <ul class="book-again">
                          <li>
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </li>
                          <li>
                            Yes I would book again
                          </li>
                        </ul>
                     </div>  
                        <p>It's the details that make Dummy such a winner. By way of comparison, consider last summer's "My Big Fat Greek Wedding," in which each actor put a heartfelt spin on his or her one-joke character.</p>
                        <p class="review-section-single-cont-date">December 31, 2023</p>
                    </div>
            
                </div> -->

              </div>

              <div *ngIf="reviews.length > spaceRatingReviewList.length"
                style="text-align: center; margin-top: 40px; margin-bottom: 20px;">
                <a (click)="nextReviewList()" class="link-hov style2 text-dark"
                  style="border: 1px solid #ccc7c7; padding: 15px 20px; font-weight: 600;cursor: pointer;">See more
                  reviews </a>
              </div>

              <div *ngIf="spaceRatingReviewList.length == 0">
                <!-- <div class="review-heading h3 text-dark">Reviews</div> -->
                <div class="cnts text-dark mt-lg-5 mt-4" style="font-size: 18px;">No review yet</div>
              </div>

            </div>

            <!--Listing Details ends-->
            <!--Similar Listing starts-->

            <!--Similar Listing ends-->
          </div>
        </div>


      </div>
      <div class="col-lg-4 col-md-12 order-lg-1 order-md-0 order-0">
        <div class="listing-sidebar" style="position: sticky; top:90px;">
          <div class="sidebar-widget info enquire-container-desktop">
            <!-- <h3><i class="ion-android-calendar"></i>Booking</h3>
            <div class="book-btn text-center">
              <a routerLink="/contact-form" [queryParams]="{space_id: space_details?.id}"> Enquire Now</a>
            </div> -->

            <div class="bookinng-sec-c">
              <div class="booking-container hour-rate" *ngIf="isShortterm">
                <!-- <p>Prices starting from</p> -->
                <h2>
                  <svg *ngIf="space_details?.isInstant == 1" viewBox="0 0 24 24" height="45px" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M11.5 13.8H10.1299C8.72143 13.8 8.01721 13.8 7.72228 13.3385C7.42735 12.8769 7.72321 12.2379 8.31493 10.9597L11.0463 5.06006C11.4205 4.25182 11.6075 3.8477 11.8038 3.89091C12 3.93413 12 4.37946 12 5.27013V9.7C12 9.9357 12 10.0536 12.0732 10.1268C12.1464 10.2 12.2643 10.2 12.5 10.2H13.8701C15.2786 10.2 15.9828 10.2 16.2777 10.6615C16.5726 11.1231 16.2768 11.7621 15.6851 13.0402L12.9537 18.9399C12.5795 19.7482 12.3925 20.1523 12.1962 20.1091C12 20.0659 12 19.6205 12 18.7299V14.3C12 14.0643 12 13.9464 11.9268 13.8732C11.8536 13.8 11.7357 13.8 11.5 13.8Z"
                        fill="#fed42d"></path>
                    </g>
                  </svg> <img src="assets/images/rs-icon.png" alt=""> {{space_details?.originalPrice}} <span> /
                    hr</span>
                </h2>
                <p class=""> {{space_details?.minimum_hours/60}} hrs minimum</p>
              </div>
              <div class="booking-container hour-rate" *ngIf="isLongterm">
                <p>Monthly rental</p>
                <h2><img src="assets/images/rs-icon.png" alt="">{{space_details?.originalPrice}}</h2>
                <span class="font-weight-normal pl-1" style="font-size: 12px;"> <img src="assets/images/rs-icon.png"
                    style="width: 8px;" alt=""> {{ space_details?.originalPrice / space_details?.spacesqft |
                  number:'1.0-0' }}/Sqft
                  <span class="font-weight-bold text-gray mb-1 mt-1" style="font-size: 11px;"
                    *ngIf="space_details?.negociable_price">(Negotiable)</span>
                </span>
                <p class="" style="font-size: 13px !important;margin-top: 5px !important;"><b>Carpet Area:</b> {{ space_details?.spacesqft | number:'1.0-0' }} sq. ft. | <b>{{space_details?.spaceStatus}}</b></p>
              </div>
              <div class="booking-container">
                <h2 class="booking-heading text-center fs-20" *ngIf="!isShortterm">Interested in this space?</h2>
                <!-- <br /> -->
                <ul *ngIf="isCoworking" class="row g-3 align-items-center">
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon me-2">
                      <img src="assets/images/tick.svg" alt="Tick Icon" class="img-fluid" />
                    </span>
                    <p class="booking-text mb-0">
                      Zero Brokerage
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon me-2">
                      <img src="assets/images/tick.svg" alt="Tick Icon" class="img-fluid" />
                    </span>
                    <p class="booking-text mb-0">
                      Best Deals
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon me-2">
                      <img src="assets/images/tick.svg" alt="Tick Icon" class="img-fluid" />
                    </span>
                    <p class="booking-text mb-0">
                      1000+ Clients Served
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon me-2">
                      <img src="assets/images/tick.svg" alt="Tick Icon" class="img-fluid" />
                    </span>
                    <p class="booking-text mb-0">
                      Expert Advisors
                    </p>
                  </li>
                </ul>


                <ul *ngIf="isLongterm" class="row g-3 align-items-center">
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon">
                      <img src="assets/images/tick.svg" alt /> </span>&nbsp;
                    <p class="booking-text">
                      1000+ Clients Served
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon">
                      <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="#000000"
                        d="M14,10V4.5L19.5,10M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V9L15,3H5Z" />
                    </svg> -->
                      <img src="assets/images/tick.svg" alt /> </span>&nbsp;
                    <p class="booking-text">
                      Expert Advisors
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon">
                      <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="#000000"
                        d="M14,10V4.5L19.5,10M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V9L15,3H5Z" />
                    </svg> -->
                      <img src="assets/images/tick.svg" alt /> </span>&nbsp;
                    <p class="booking-text">
                      End-to-End Support
                    </p>
                  </li>
                  <li class="col-6 col-md-6 d-flex align-items-center">
                    <span class="booking-icon">
                      <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="#000000"
                        d="M14,10V4.5L19.5,10M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V9L15,3H5Z" />
                    </svg> -->
                      <img src="assets/images/tick.svg" alt /> </span>&nbsp;
                    <p class="booking-text">
                      Best Deals
                    </p>
                  </li>
                </ul>

                <div class="btn-grp-c mt-0">
                  <!-- <div class="book-btn text-center" style="margin-top: 30px">
                          <a routerLink="/contact-form" [queryParams]="{ space_id: space_details?.id }">
                            GET A QUOTE</a>
                        </div> -->
                  <div *ngIf="isShortterm">
                    <a *ngIf="space_details?.isInstant == 0 && !isMobile" (click)="openRequestBookPopup()" class="custum-btn d-none d-sm-block">
                      REQUEST TO BOOK</a>
                    <a *ngIf="space_details?.isInstant == 1 && !isMobile" (click)="openRequestBookPopup()" class="custum-btn d-none d-sm-block">
                      BOOK NOW</a>
                    <div class="mt-4" *ngIf="space_details?.isInstant == 1">
                      <div>
                        <span class="mb-2">
                          <svg *ngIf="space_details?.isInstant == 1" viewBox="0 0 24 24" height="35px" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M11.5 13.8H10.1299C8.72143 13.8 8.01721 13.8 7.72228 13.3385C7.42735 12.8769 7.72321 12.2379 8.31493 10.9597L11.0463 5.06006C11.4205 4.25182 11.6075 3.8477 11.8038 3.89091C12 3.93413 12 4.37946 12 5.27013V9.7C12 9.9357 12 10.0536 12.0732 10.1268C12.1464 10.2 12.2643 10.2 12.5 10.2H13.8701C15.2786 10.2 15.9828 10.2 16.2777 10.6615C16.5726 11.1231 16.2768 11.7621 15.6851 13.0402L12.9537 18.9399C12.5795 19.7482 12.3925 20.1523 12.1962 20.1091C12 20.0659 12 19.6205 12 18.7299V14.3C12 14.0643 12 13.9464 11.9268 13.8732C11.8536 13.8 11.7357 13.8 11.5 13.8Z"
                                fill="#fed42d"></path>
                            </g>
                          </svg>
                          <span class>Instant Book</span>
                        </span>

                        <p class="text-gray-dark text-small pl-3">
                          <span>After payment, your booking will be instantly confirmed.</span>
                        </p>
                      </div>

                    </div>
                  </div>

                  <div class="mt-3" *ngIf="isCoworking">
                    <a (click)="openInquiryPopUp()" class=" custum-btn">
                      Get Quote</a>
                  </div>
                  <div *ngIf="isLongterm">
                    <a (click)="openInquiryPopUp()" class=" custum-btn">
                      Request A Call Back</a>
                  </div>
                  <div *ngIf="isLongterm">
                    <a style="background: #fff  !important; color: #F76900 !important;"
                      (click)="openScheduleVisitPopup()" class="custum-btn  blu-btn">
                      Schedule a visit</a>
                  </div>
                  <div *ngIf="isCoworking">
                    <a style="background: #fff  !important; color: #000e54 !important;"
                      (click)="openScheduleVisitIsCoworkingPopup()" class="custum-btn  blu-btn">
                      Schedule a visit</a>
                  </div>
                  <div *ngIf="isCoworking && space_details?.originalPrice > 0">
                    <a (click)="openRequetBuyPassPopup()"
                      style="background: #2c864f; color: #fff !important; border: none !important;"
                      class="custum-btn outline-blk-btn boking_btn">
                      BUY PASS</a>
                  </div>

                </div>

              </div>
              <div class="booking-container" *ngIf="!isShortterm" sty>
                <p class="booking-text2 text-center">Speak to our office space experts.</p>
                <a hrf="tel:+919513392400" class="booking-call text-center" style="display: block;">Call +91 95133
                  92400</a>
              </div>
            </div>
            <!-- <div class="booking-container">
              <p class="booking-heading">Interested in scheduling a visit to this space?</p>
              <br />
              <div>
                <span class="booking-icon" style="color: #F76900; font-size: x-large;">
                  <i class="ion-android-calendar"></i> </span>&nbsp;
                <p class="booking-text">
                  Schedule an offline visit to this space
                </p>
              </div>
              <div class="book-btn text-center" style="margin-top: 30px">
                <a (click)="openScheduleVisitPopup()">
                  REQUEST TO BOOK</a>
              </div>
              <div class="book-btn text-center" style="margin-top: 10px">
                <a (click)="openScheduleVisitPopup()">
                  Schedule a visit</a>
              </div>
            </div> -->
            <!-- <div class="booking-container">
              <p class="booking-heading">Review this workspace</p>
              <br />
              <div>
                <span class="booking-icon" style="color: #F76900; font-size: x-large;">
                  <i class="ion-android-star"></i> </span>&nbsp;
                <p class="booking-text">
                  Share your thoughts with other user
                </p>
              </div>
              <div class="book-btn text-center" style="margin-top: 30px">
                <a (click)="onRatingReview()">
                  Write a review</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 60px">
      <div class="col-lg-12">
        <div class="similar-listing-title" id="similar-listing-title">
          <h2 style="font-size: 24px;">Nearby <span *ngIf="spaceType != 'coworking space'">{{spaceType | titlecase}}</span> <span *ngIf="spaceType == 'coworking space'">{{spaceType.split(" ")[0] | titlecase}}</span> Spaces</h2>
        </div>
        <!-- <div class="swiper-container similar-list-wrap">
        <div class="swiper-wrapper"> -->
        <ngx-slick-carousel class="carousel" #slickSimilarSpacesCarousel="slick-carousel" [config]="similarSpacesConfig"
          responsive="breakpoints">
          <div ngxSlickItem class="similar-item-slide slide" *ngFor="let element of space_details?.similar_spaces">
            <app-list-item [spaceDetails]="element" [is_similar_listing]="true">
            </app-list-item>
            <!-- <img [src]="aws_base_url + space_details?.id + '/' + element.images[0]" class="img-fluid" alt="...">
            <div class="similar-title-box">
              <h5><a routerLink="coworking-space/{{element.link_name}}">{{element.name}}</a></h5>
              <p><span class="price-amt">$89</span>/Avg</p>
            </div>
            <div class="customer-review">
              <div class="rating-summary">
                <div class="rating-result" title="60%">
                  <ul class="product-rating">
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star-half"></i></li>
                    <li><i class="ion-android-star-half"></i></li>
                  </ul>
                </div>
              </div>
              <p><i class="ion-ios-location"></i>{{element.address}}</p>
            </div> -->
          </div>
        </ngx-slick-carousel>

        <!-- </div>
      </div> -->
        <div class="slider-btn v3 similar-next" (click)="next1()">
          <i class="ion-arrow-right-c"></i>
        </div>
        <div class="slider-btn v3 similar-prev" (click)="prev1()">
          <i class="ion-arrow-left-c"></i>
        </div>
      </div>
    </div>
    <!-- <div id="review_rating" class="row"
      style="margin-top: 60px; background-color: #fff;">
      <div class="col-md-12">
        <div class="list-details-section">
          <h4>Review & Rating</h4>
          <div class="row">
            <div class="col-md-3 overall-rating-div-main">
              <h5> <i class="ion-ios-circle-filled"
                  style="color: #0095ff;"></i> Overall Rating</h5>
              <div style="text-align: center;">
                <span class="overall-rating-spn-rated">{{space_details?.rating
                  || 0}}</span> <span
                  class="overall-rating-spn-rate">/5</span> <br>
                <span class="overall-rating-spn-star"
                  *ngFor="let element of rating_array; index as erai"
                  [innerHTML]="showStarIcon(erai, space_details?.rating)"></span>
                <br>
                <span
                  style="font-weight: 500;">{{spaceRatingReviewList.length}}
                  reviews</span>
              </div>
            </div>

            <div class="col-md-3 rating-breakdown-div-main">
              <h5> <i class="ion-ios-circle-filled"
                  style="color: #2ac7a3;"></i> Rating Breakdown</h5>
              <div *ngFor="let rbd of ratingBreakDown"
                class="row rating-breakdown-div-star">
                <span
                  [ngStyle]="{'padding-left': rbd.star === 1 ? '5px' : '', 'padding-right': '5px'}">{{rbd.star}}</span>
                <span class="rating-breakdown-spn-star"><i
                    class="ion-android-star"></i></span>
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                    aria-valuemin="0" aria-valuemax="100"
                    [ngStyle]="{ 'width': rbd.value + '%', 'background-color': rbd.backgroundColor }"></div>
                </div>
                <span class="rating-breakdown-spn-rated">{{rbd.value}}</span>
              </div> <br>
            </div>
          </div>
          <div class="col-md-12" *ngIf="spaceRatingReviewList.length > 0">
            <div class="row reviewed-main-div"
              *ngFor="let srrl of spaceRatingReviewList"
              [ngStyle]="{'background-color': srrl.currentUser === true ? '#fdf5e6' : ''}">
              <div class="col-md-1"><img src="assets/images/user.png"
                  style="width: 100% !important;" /></div>
              <div class="col-md-2">
                <h6>{{srrl.userName}}</h6>
                <span>{{ srrl.createdAt | date: 'MMMM d, y' }}</span> <br>
                <span>{{ calculateDateDifference(srrl.createdAt,
                  currentDateTime) }}</span> <br>
              </div>
              <div class="col-md-9">
                <div style="display: flex;">
                  <div class="col-md-8" style="padding-left: 0px;">
                    <span class="overall-rating-spn-star"
                      *ngFor="let element of rating_array; index as erai"
                      [innerHTML]="showStarIcon(erai, srrl.rating)"></span>
                    <br>
                  </div>
                  <div class="col-md-4" style="text-align: end;">
                    <span *ngIf="srrl.currentUser === true"
                      class="reviewed-icon-update" (click)="onRatingReview()"
                      title="Update Review"><i class="ion-compose"></i></span>
                    <span class="reviewed-icon-thumb thumbsup"> <i class="ion-thumbsup"></i></span>
                    <span class="reviewed-icon-thumb thumbsdown"> <i class="ion-thumbsdown"></i></span>
                  </div>
                </div>
                <div>
                  <p>{{ srrl.Review }}</p>
                  <p class="less-content" id="see-more-h-{{srrl.ratingId}}">{{ srrl.Review }}</p>
                  <p *ngIf="!is_see_more_visible" id="see-more-p-{{srrl.ratingId}}">{{ srrl.Review }}</p>
                </div>
                <a *ngIf="is_see_more_visible" id="see-more-{{srrl.ratingId}}" style="font-size: 12px; font-weight: 600; cursor: pointer" (click)="toggleSeeMore($event, 0, srrl.ratingId)">See more</a>
                <a *ngIf="!is_see_more_visible" id="see-less-{{srrl.ratingId}}" style="font-size: 12px; font-weight: 600; cursor: pointer;" (click)="toggleSeeMore($event, 1, srrl.ratingId)">See less</a>
              </div>
            </div>
            <div style="margin-left: -15px !important;">
              <a routerLink="/coworking-space/view-more-review/{{space_name}}"
                class="link-hov style2">See more reviews <i
                  class="icon ion-arrow-right-c"></i></a>
            </div>
          </div>
          <div class="col-md-12" *ngIf="spaceRatingReviewList.length == 0">
            <div class="row reviewed-main-div" style="align-items: center;">
              <h6>No records found Reviews & Ratings for this workspace.</h6>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <app-faqs></app-faqs>
</div>
<!-- <div *ngIf="isMobile" class="bottom-fixed-container" style="width: 98%;">
  <div class="find-office-container">
    <p class="booking-text"><span>Interested in this office space?</span></p>
  </div>
  <div class="book-btn text-center bottom-fixed-button" style="margin-top: 3px">
    <a routerLink="/contact-form" [queryParams]="{ space_id: space_details?.id }" style="font-size: 17px">GET A
      QUOTE</a>
  </div>
</div> -->
<div class="mobile_btm_bar py-3 px-3 ">
  <ul class="p-0 m-0 socail_btn_list">
    <div class="row justify-content-around">
      <div *ngIf="isShortterm">
        <a *ngIf="space_details?.isInstant == 0" (click)="openRequestBookPopup()" class="custum-btn">
          REQUEST TO BOOK</a>
        <a *ngIf="space_details?.isInstant == 1" (click)="openRequestBookPopup()" class="custum-btn">
          BOOK NOW</a>
    </div>
    </div>
  </ul>
</div>
<div class="mobile_btm_bar py-3 px-3 " *ngIf="['coworking space', 'managed office', 'private office', 'shared office', 'virtual office'].includes(spaceType)">
  <ul class="p-0 m-0 socail_btn_list">
    <div class="row justify-content-around">
      <div class="col-3">
        <li>
         <a class="" (click)="openInquiryPopUp()" style="color: #ffffff;">
           <svg class="svgIconBottom" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg"><path d="M644.7 669.2a7.92 7.92 0 0 0-6.5-3.3H594c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.8-5.3 0-12.7-6.5-12.7h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-22.9-31.9zM688 306v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 458H208V148h560v296c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h312c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm402.6-320.8l-192-66.7c-.9-.3-1.7-.4-2.6-.4s-1.8.1-2.6.4l-192 66.7a7.96 7.96 0 0 0-5.4 7.5v251.1c0 2.5 1.1 4.8 3.1 6.3l192 150.2c1.4 1.1 3.2 1.7 4.9 1.7s3.5-.6 4.9-1.7l192-150.2c1.9-1.5 3.1-3.8 3.1-6.3V538.7c0-3.4-2.2-6.4-5.4-7.5zM826 763.7L688 871.6 550 763.7V577l138-48 138 48v186.7z"></path></svg>
           <div>QUOTES</div>
        </a> 
       </li>
      </div>
      <div class="col-3">
        <li>
          <a class="" style="color: #ffffff;"  href="https://api.whatsapp.com/send/?phone=919136153810&text=Hi&type=phone_number&app_absent=0">
            <svg class="svgIconBottom" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="45px" width="45px" xmlns="http://www.w3.org/2000/svg"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
            <div>WHATSAPP</div>
         </a> 
        </li>
      </div>
      <div class="col-3">
        <li>
          <a class="" style="color: #ffffff;" href="" >
            <svg class="svgIconBottom" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="45px" width="45px" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linejoin="round" stroke-width="32" d="M408 64H104a56.16 56.16 0 0 0-56 56v192a56.16 56.16 0 0 0 56 56h40v80l93.72-78.14a8 8 0 0 1 5.13-1.86H408a56.16 56.16 0 0 0 56-56V120a56.16 56.16 0 0 0-56-56z"></path><circle cx="160" cy="216" r="32"></circle><circle cx="256" cy="216" r="32"></circle><circle cx="352" cy="216" r="32"></circle></svg>
            <div>CHAT</div>
         </a> 
        </li>
      </div>
      <div class="col-3">
        <li>
          <a class="" style="color: #ffffff;" href="tel:+91 95133 92400">
            <svg class="svgIconBottom" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="42px" width="42px" xmlns="http://www.w3.org/2000/svg"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            <div>CALL</div>
         </a> 
        </li>
      </div>
    </div>
  </ul>
</div>
<!--Listing Details Info ends-->
<!-- Scroll to top starts-->
<!-- Scroll to top ends-->
<ngx-json-ld [json]="schema"></ngx-json-ld>
<app-co-working-visit-schedule #modal></app-co-working-visit-schedule>