<div class="booking-popup position-relative">
  <div class="booking-popup-heading booking_cls_btn position-absolute">
    <button type="button" [mat-dialog-close]="true" class="btn-close">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="booking-popup-left booking-popup-half">
    <div class="booking-popup-heading">
      <h4 *ngIf="space_details?.isInstant == 0">Request To Book</h4>
      <h4 *ngIf="space_details?.isInstant == 1">Complete Your Booking</h4>
      <!-- <h4>Complete your Booking</h4> -->
    </div>

    <div class="booking-popup-all-input">
      <div class="booking-popup-only-input">
        <div *ngFor="let booking of bookings; let i = index">
          <!-- <button (click)="removeBooking(i)" *ngIf="bookings&&bookings.length>1">remove</button> -->
          <div class="d-flex justify-content-end mr-3">
            <mat-icon (click)="removeBooking(i)" *ngIf="bookings&&bookings.length>1 && i!=0">close</mat-icon>
          </div>
          <div class="booking-popup-input-single">
            <mat-form-field>
              <mat-label>Choose a date</mat-label>
              <!-- <input (dateChange)="space_details.has_247_access === 0 ? onDateChange($event) : null" [matDatepickerFilter]="space_details.has_247_access === 0 ? dateFilter : null" matInput (click)="picker.open()" readonly [matDatepicker]="picker" [(ngModel)]="booking.date"
                name="date_{{i}}" [min]="minDate"
                (ngModelChange)="dateChanged(i, $event); booking.startTime=''; booking.endTime='';"> -->

              <input (dateChange)="onDateChange($event)" [matDatepickerFilter]="dateFilter" matInput
                (click)="picker.open()" readonly [matDatepicker]="picker" [(ngModel)]="booking.date" name="date_{{i}}"
                [min]="minDate" (ngModelChange)="dateChanged(i, $event); booking.startTime=''; booking.endTime='';">

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error *ngIf="isFormSubmitted && !booking?.date">Please select a date</mat-error>
          </div>
          <div class="booking-popup-all-input-group">
            <div class="booking-popup-input-single">
              <mat-form-field>
                <mat-label>Start time</mat-label>
                <mat-select [(ngModel)]="booking.startTime" name="start_time_{{i}}"
                  (ngModelChange)="startTimeChanged(i)">
                  <!-- <mat-option [disabled]="isTimeDisabled(i, startTime.value)" *ngFor="let startTime of space_details.has_247_access === 0 ? filteredStartTimes : startTimes"
                    [value]="startTime.value"> -->

                  <mat-option [disabled]="isTimeDisabled(i, startTime.value)" *ngFor="let startTime of startTimes"
                    [value]="startTime.value">
                    {{startTime.viewValue}}
                    <span *ngIf="startTime.viewValue === '12:00 AM' "> Midnight</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="isFormSubmitted && !booking.startTime">Please select a start time</mat-error>
            </div>
            <div class="booking-popup-input-single">
              <mat-form-field>
                <mat-label>End time</mat-label>
                <mat-select [(ngModel)]="booking.endTime" name="end_time_{{i}}" (ngModelChange)="endTimeChanged(i)">
                  <!-- <mat-option *ngFor="let endTime of space_details.has_247_access === 0 ? filteredStartTimes : endTimes" [value]="endTime.value"> -->

                  <mat-option [disabled]="isTimeDisabled(i, endTime.value)" *ngFor="let endTime of endTimes"
                    [value]="endTime.value">
                    {{endTime.viewValue}}
                    <span *ngIf="endTime.viewValue === '12:00 AM' "> Midnight</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="isFormSubmitted && !booking.endTime">Please select end time</mat-error>
            </div>
          </div>
        </div>
        <a class="bg-t-btn" (click)="addBooking()">Add Another Slot</a>
      </div>

      <div class="d-md-block d-none mb-3">
        <button *ngIf="space_details?.isInstant == 0" [ngClass]="isLoading?'disabled':''" type="submit"
          class="custum-btn next-step" (click)="onSumbit()">Submit Request</button>
        <button *ngIf="space_details?.isInstant == 1" [ngClass]="isLoading?'disabled':''" type="submit"
          class="custum-btn next-step" (click)="onSumbit()">Book Now</button>
      </div>
    </div>

    <p *ngIf="space_details?.isInstant == 0" class="booking-popup-input-cont">You will not be charged yet. Your booking
      request will be sent to the host. Once the host accepts your booking request, you will receive a link to make the
      payment.</p>
  </div>
  <div class="booking-popup-right booking-popup-half position-relative">

    <div class="booking-popup-plot align-items-md-center align-items-start">
      <!-- || '"assets/images/add-on-img-1.jpg"' -->
      <div class="booking-popup-plot-img">
        <img [src]="space_details?.images[0] ?? 'assets/images/details_placeholder_image.jpg'" alt="">
      </div>
      <div class="booking-popup-plot-cont pl-md-3">
        <h5>{{spaceName}}</h5>
        <p style="text-transform: capitalize;"> <i class="fa fa-map-marker" aria-hidden="true"></i> <span> {{landmark}}
          </span> </p>
        <ul class="product-rating-c" *ngIf="space_details?.ratingsAvg > 0">
          <li class>
            <i style="cursor: context-menu;"
              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 1, 'fa-star-half-o': space_details?.ratingsAvg > 0 && space_details?.ratingsAvg < 1, 'fa-star-o': space_details?.ratingsAvg <= 0}"
              aria-hidden="true"></i>
          </li>
          <li class>
            <i style="cursor: context-menu;"
              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 2, 'fa-star-half-o': space_details?.ratingsAvg > 1 && space_details?.ratingsAvg < 2, 'fa-star-o': space_details?.ratingsAvg <= 1}"
              aria-hidden="true"></i>
          </li>
          <li class>
            <i style="cursor: context-menu;"
              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 3, 'fa-star-half-o': space_details?.ratingsAvg > 2 && space_details?.ratingsAvg < 3, 'fa-star-o': space_details?.ratingsAvg <= 2}"
              aria-hidden="true"></i>
          </li>
          <li class>
            <i style="cursor: context-menu;"
              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 4, 'fa-star-half-o': space_details?.ratingsAvg > 3 && space_details?.ratingsAvg < 4, 'fa-star-o': space_details?.ratingsAvg <= 3}"
              aria-hidden="true"></i>
          </li>
          <li class>
            <i style="cursor: context-menu;"
              [ngClass]="{'fa': true, 'fa-star': space_details?.ratingsAvg >= 5, 'fa-star-half-o': space_details?.ratingsAvg > 4 && space_details?.ratingsAvg < 5, 'fa-star-o': space_details?.ratingsAvg <= 4}"
              aria-hidden="true"></i>
          </li>
        </ul>
        <div class="booking-container hour-rate align-items-start border-0">
          <p style="font-weight: 700;">
            <img src="assets/images/rs-icon.png" style=" height: 15px;" alt=""> {{space_details?.originalPrice}} <span>
              / hr</span>
            <span style="font-weight: 500; padding-left: 5px;">{{space_details?.minimum_hours / 60}} hr minimum</span>
          </p>

        </div>
      </div>


    </div>
    <div class="booking-popup-payment-info">
      <div class="booking-popup-payment-info-date">
        <h5>
          <!-- <i class="fa fa-calendar-o" aria-hidden="true"></i> -->
          <!-- <span>{{selectedDate  | date:'EEE MMM dd, yyyy'}}</span> -->
          Booking Summary
        </h5>
      </div>
      <!-- <div class="booking-popup-payment-info-details"> -->
      <!-- <div class="booking-popup-payment-info-details-single">
          <p> <span>{{spaceName}} </span> <span>{{originalPrice}} INR</span> <span *ngIf="totalHours"> x </span> <span
              *ngIf="totalHours"> {{totalHours || 0}} hours</span> x <span>{{gettotalDays()}} days</span> </p>
          <h6> {{originalPrice * totalHours || 0 }} INR</h6>
        </div> -->

      <!-- <span> {{days}} days</span> x  -->

      <!-- </div> -->
      <!-- <div class="booking-popup-payment-info-deposit">
                <p> Deposit </p>
                <h6>{{bookingPrice}} INR</h6>
            </div>

           

            <div class="booking-popup-payment-info-details" *ngFor="let item of bookings">
                <div class="booking-popup-payment-info-details-single"> -->
      <!-- <h5> <i class="fa fa-calendar-o" aria-hidden="true"></i> <span>{{item?.date| date:'EEE MMM dd, yyyy'}}</span></h5><span> {{getHours(item?.endTime,item?.startTime) ||'' }} hours</span> x <span>{{originalPrice}} INR</span> <span>{{originalPrice * getHours(item?.endTime,item?.startTime)}}</span> -->
      <!-- </div> -->
      <!-- </div> -->
      <div style="overflow-y: scroll;height: 142px;">
        <div *ngFor="let item of bookings">
          <span
            class="d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-between pr-2 "
            style="color: #000; font-size: 14px; font-weight: 500; line-height: 2;">
            <span>{{ item.minDate | date:'EEE MMM dd, yyyy' }}, <span class="pl-1"><img src="assets/images/rs-icon.png"
                  style=" height: 13px;" alt=""> {{ originalPrice }} <span *ngIf="item.duration">x</span>
                {{item.duration}} <span *ngIf="item.duration">hours</span> </span> </span>
            <span class=""><img src="assets/images/rs-icon.png" style=" height: 13px;" alt=""> {{ originalPrice *
              item.duration | number: '0.2-2' }}</span>
          </span>
        </div>
      </div>
      <div class="booking-popup-payment-info-total">
        <p>Subtotal</p>
        <h6>{{originalPrice * totalHours || 0 | number: '0.2-2'}} <img src="assets/images/rs-icon.png"
            style=" height: 13px;" alt=""></h6>
      </div>
      <div class="booking-popup-payment-info-total">
        <p> GST(18%) </p>
        <h6>{{ ((originalPrice * totalHours) > 0 ? (originalPrice * totalHours) * 0.18 : 0) | number: '0.2-2' }} <img
            src="assets/images/rs-icon.png" style=" height: 13px;" alt=""></h6>
      </div>
      <div class="booking-popup-payment-info-pay">
        <h5> Total </h5>
        <h5>{{ ((originalPrice * totalHours) + ((originalPrice * totalHours) > 0 ? (originalPrice * totalHours) * 0.18 :
          0)) | number: '0.2-2' }} <img src="assets/images/rs-icon.png" style=" height: 13px;" alt=""></h5>
      </div>

    </div>

    <!-- <div class="booking-popup-payment-contrnt">
      <p>The amount payable now is a non-refundable deposit to reserve your day pass. The balance of <span>
          {{originalPrice * totalHours || 0}} INR</span> + applicable taxes is payable on arrival at the property, in
        the property's
        currency.</p>
      <p>Prices on the next page will be converted into US dollars.</p>
    </div> -->
    <div class="d-md-none d-block text-center mt-3">
      <button *ngIf="space_details?.isInstant == 0" [ngClass]="isLoading?'disabled':''" type="submit"
        class="custum-btn next-step" (click)="onSumbit()">Submit Request</button>
      <button *ngIf="space_details?.isInstant == 1" [ngClass]="isLoading?'disabled':''" type="submit"
        class="custum-btn next-step" (click)="onSumbit()">Book Now</button>
    </div>

  </div>

</div>

<div class="modal" id="updateModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Profile</h5>
        <button type="button" class="close" data-dismiss="updateModal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="pm-single">
            <p>GST No.</p>
            <mat-form-field appearance="outline" class="w-100">
              <input (keyup)="chkValidateGstPanForm($event,'GSTnumber')" type="text" value="{{GSTnumber}}" matInput
                placeholder="Enter GST No." oninput="this.value = this.value.toUpperCase()"
                pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$" maxlength="15" />
            </mat-form-field>
            <!-- <small *ngIf="gstErrorMsg" class="text-danger">{{gstErrorMsg}}*</small> -->
          </div>
          <!-- <div class="pm-single">
            <p>PAN No.</p>
            <mat-form-field appearance="outline" class="w-100">
              <input (keyup)="chkValidateGstPanForm($event,'PANnumber')" type="text" matInput value="{{PANnumber}}"
                placeholder="Enter PAN No." oninput="this.value = this.value.toUpperCase()"
                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" maxlength="10" />
            </mat-form-field>
            <small *ngIf="panErrorMsg" class="text-danger">{{panErrorMsg}}*</small>
          </div> -->
          <div class="pm-single">
            <p>Billing name and address <span class="text-danger">*</span> </p>
            <mat-form-field appearance="outline" class="w-100">
              <textarea (keyup)="chkValidateGstPanForm($event,'billingAddress')" type="text" matInput
                value="{{billingAddress}}" placeholder="Enter Billing Address"></textarea>
            </mat-form-field>
            <small *ngIf="billingMsg" class="text-danger">{{billingMsg}}*</small>
          </div>
          <div class="pm-single">
            <p>Billing address 2</p>
            <mat-form-field appearance="outline" class="w-100">
              <textarea (keyup)="chkValidateGstPanForm($event,'billingAddress2')" type="text" matInput
                value="{{billingAddress2}}" placeholder="Enter Billing Address"></textarea>
            </mat-form-field>
            <!-- <small *ngIf="billingMsg" class="text-danger">{{billingMsg}}*</small> -->
          </div>
        </form>
      </div>
      <div class="modal-footer gstbtn-grp-c">
        <button type="button" class="outline-orng-btn custum-btn" data-dismiss="updateModal"
          (click)="closeModal()">Close</button>
        <button type="button" class="custum-btn" (click)="submitGstAndPan()">Save changes</button>
      </div>
    </div>
  </div>
</div>