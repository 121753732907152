<div *ngIf="spaceDetails.ribbon_name" class="ribbon-label" [style.backgroundColor]="spaceDetails.ribbon_color">
  {{spaceDetails.ribbon_name}}
</div>
<div (click)="onSpaceNameClicked(spaceDetails)" class="cursor_pointer trending-place-item position-relative box-shadow">
  <div class="trending-img position-relative">
    <ngx-slick-carousel class="carousel" #slickMainCarousel="slick-carousel" [config]="mainSliderConfig"
      responsive="breakpoints">
      <ng-container *ngIf="spaceDetails.images?.length">
        <div ngxSlickItem class="swiper-slide single-listing-slider slide" *ngFor="let image of spaceDetails.images">
          <a style="cursor: pointer;">
            <img [src]="image" (error)="onImageError($event,spaceDetails.imageAlt)"
              alt="{{spaceDetails.imageAlt}} {{image | slice:(image?.lastIndexOf('/') + 1) }}" />
          </a>
        </div>
      </ng-container>
      <ng-container *ngFor="let repeat of getArray(spaceDetails.images?.length)">
        <div *ngIf="!spaceDetails.images?.length" ngxSlickItem class="swiper-slide single-listing-slider slide">
          <a style="cursor: pointer;">
            <img src="assets/images/details_placeholder_image.jpg" />
          </a>
        </div>
      </ng-container>
    </ngx-slick-carousel>
    <a class="carousel-control-prev" style="top: 45%" (click)="prev($event)" role="button" data-slide="prev">
      <span class="icon ion-chevron-left icon-color"></span>
      <!-- <span class="sr-only">Previous</span> -->
    </a>
    <a class="carousel-control-next" style="top: 45%" (click)="next($event)" role="button" data-slide="next">
      <span class="icon ion-chevron-right icon-color"></span>
      <!-- <span class="sr-only">Next</span> -->
    </a>
    <div class="shortlist-share-container">
      <div class="share-btn" style="margin-right: 10px">
        <a class="btn" [style.color]="spaceDetails?.existingfavorite?.favourite ? '#F76900' : 'gray'"
          (click)="shortListItem($event,spaceDetails.id, spaceDetails.is_shortlisted)"><i class="ion-heart"></i>
        </a>
      </div>
      <div class="share-btn">
        <a class="btn"><i class="ion-android-share-alt" style="bottom: 4px"></i></a>
        <ul class="social-share" style="top: 45px; right: -16px">
          <li class="bg-fb" (click)="openFacebook()">
            <a class="share-button"><i class="ion-social-facebook"></i></a>
          </li>
          <li class="bg-tt" (click)="openLinkedIn()">
            <a class="share-button"><i class="ion-social-linkedin"></i></a>
          </li>
          <li class="bg-ig" (click)="openWhatsapp()">
            <a class="share-button"><i class="ion-social-whatsapp"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="trending-img" *ngIf="is_similar_listing">
    <a [href]="webDomain + 'details/' + spaceDetails.link_name" target="_blank">
      <img (error)="handleImageError($event,spaceDetails.imageAlter)"
        [src]="aws_base_url + spaceDetails.id + '/' + spaceDetails.images[0]" 
         alt="{{spaceDetails.imageAlter}} {{spaceDetails.images[0] | slice:(spaceDetails.images[0]?.lastIndexOf('/') + 1) }}"/></a>
  </div> -->
  <div class="trending-title-box">

    <div class="customer-review d-flex justify-content-between mb-md-2 mb-1 align-items-end">
      <h3 style="font-size: 18px" class="h-cursor m-0 text-truncate">
        {{ spaceDetails.name
        }}
        <span style="
            background: unset;
            float: unset;
            margin: unset;
            width: unset;
            height: unset;
            font-size: 15px;
          ">
          <i class="fa-solid fa-location-dot" style="color: #f76900;"></i>
          {{spaceDetails.location_name }}, {{spaceDetails.contact_city_name}}</span>
      </h3>
    </div>
    <div class="rating-summary d-flex align-items-center mb-1" style="">
      <div class="rating-result d-flex align-items-center pr-2" style="" title="60%"
        *ngIf="spaceDetails.rating && (type === 'coworking' || type === 'shortterm' ) && spaceDetails.reviewCount.length > 0">
        <ul class="product-rating pr-1">
          <li *ngFor="let element of spaceDetails.rating_array">
            <i class="ion-android-star"></i>
          </li>
          <!-- <li><i class="ion-android-star"></i></li>
          <li><i class="ion-android-star"></i></li> -->
          <li *ngIf="spaceDetails.rating - spaceDetails.rating_floor != 0">
            <i class="ion-android-star-half"></i>
          </li>
          <li *ngFor="let element of spaceDetails.empty_star_array">
            <i class="ion-android-star-outline"></i>
          </li>
        </ul>
        {{spaceDetails.rating}} ({{spaceDetails.reviewCount}})
      </div>
      <div class="d-flex align-items-center">
        <div *ngIf="type === 'coworking' || type === 'shortterm'  " class="pr-1 rate_txt">
          <i class="fa fa-user" style="color: #f76900;"></i> &nbsp;{{spaceDetails.howManyPeopleInYourSpace}} people
        </div>
        <div *ngIf="type === 'longterm'" class="rate_txt">
          <i class="fa-solid fa-house" style="color: #f76900;"></i> {{spaceDetails.spaceStatus}}
        </div>
        <div class="rate_txt">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="18" viewBox="0 0 24 24"
            width="18" style="fill: #f76900;vertical-align: sub;">
            <path d="m0 0h24v24h-24z" fill="none" />
            <path
              d="m17.66 17.66-1.06 1.06-.71-.71 1.06-1.06-1.94-1.94-1.06 1.06-.71-.71 1.06-1.06-1.94-1.94-1.06 1.06-.71-.71 1.06-1.06-1.95-1.95-1.06 1.06-.71-.71 1.06-1.06-1.94-1.94-1.06 1.06-.71-.71 1.06-1.06-2.34-2.34v14c0 1.1.9 2 2 2h14zm-10.66-.66v-5.76l5.76 5.76z" />
          </svg>{{spaceDetails.spacesqft}} sqft
        </div>
      </div>
    </div>
    <div class="d-flex" style="gap:30px;align-items: baseline;">
      <div *ngIf="type === 'shortterm' || type === 'longterm'">
        <span class="accent_back">
          <svg style="scale: .2;" version="1.1" xmlns="http://www.w3.org/2000/svg" width="42" height="60">
            <path
              d="M0 0 C13.86 0 27.72 0 42 0 C42 2.64 42 5.28 42 8 C38.04 8 34.08 8 30 8 C30.495 8.94875 30.99 9.8975 31.5 10.875 C33 14 33 14 33 16 C35.97 16 38.94 16 42 16 C42 18.97 42 21.94 42 25 C39.03 25 36.06 25 33 25 C32.6596875 26.5778125 32.6596875 26.5778125 32.3125 28.1875 C30.51419436 34.42497969 25.44689864 37.87093057 20 41 C18.68 41 17.36 41 16 41 C21.31429814 47.17252553 21.31429814 47.17252553 27 53 C25.70700306 55.88437779 24.34058637 57.87219421 22 60 C16.5852468 57.64346638 12.88182545 53.29027355 8.75 49.1875 C7.90953125 48.37990234 7.0690625 47.57230469 6.203125 46.74023438 C5.01203125 45.55977539 5.01203125 45.55977539 3.796875 44.35546875 C2.70391113 43.28268677 2.70391113 43.28268677 1.58886719 42.18823242 C-0.33321464 39.54108746 -0.49327943 38.20332474 0 35 C2.72728976 32.27271024 4.44519804 32.70583158 8.25 32.5625 C13.16548702 32.25290579 16.86582159 31.90509834 21 29 C21.66 28.01 22.32 27.02 23 26 C11.615 25.505 11.615 25.505 0 25 C0 22.03 0 19.06 0 16 C7.59 16 15.18 16 23 16 C18.57285488 10.4660686 17.1864717 9.37157976 10.44921875 8.5859375 C9.20785156 8.51632812 7.96648438 8.44671875 6.6875 8.375 C5.43324219 8.30023438 4.17898437 8.22546875 2.88671875 8.1484375 C1.93410156 8.09945313 0.98148437 8.05046875 0 8 C0 5.36 0 2.72 0 0 Z "
              fill="#fff" transform="translate(0,0)" />
          </svg>

          <span class="price-value text-white ms-10 align-middle">{{spaceDetails?.originalPrice}} </span>
          <small> / {{type === 'shortterm' ? 'hour' : 'month' }}</small></span>
      </div>
      <div *ngIf="spaceDetails.isInstant && type === 'shortterm'">
        <i class="fa fa-bolt bolt_icon"></i><span class="instant_back p-1 text-dark">Instant Book</span>
      </div>
      <div *ngIf="spaceDetails.spaceStatus?.toLowerCase() === 'furnished' && type === 'longterm'">
        <span class="status_back p-1 text-white"> {{spaceDetails.spaceStatus}}</span>
      </div>
    </div>
    <div *ngIf="type === 'coworking'">
      <div class="coworkingPlaceClass">
        <div class="inventory-label row m-0">
          <p class="col-lg-6 col-md-6 col-6 p-0">Private Office from</p>
          <div class="col-lg-6 col-md-6 col-6 p-0">
            <p class="amountClass">
              <ng-container *ngIf="spaceDetails?.privatecabin_price">
                <div class="m-0 d-flex align-items-baseline">
                  <img src="assets/images/rs-icon.png" class="rs_icon " alt="">
                  <span class="price-value">{{spaceDetails?.privatecabin_price}}</span>
                  <small class="pl-1"> per seat/month</small>
                </div>
              </ng-container>
            </p>
            <p *ngIf="spaceDetails?.privatecabin_price == null || spaceDetails?.privatecabin_price == 0">NA</p>
          </div>
        </div>
        <!-- <div class="inventory-label" >
        <p>Private Cabins from</p>
      </div> -->
        <div class="inventory-label row m-0">
          <p class="col-lg-6 col-md-6 col-6 p-0">Desks from</p>
          <div class="col-lg-6 col-md-6 col-6 p-0">
            <p class="amountClass">
              <ng-container *ngIf="spaceDetails.flexible_desk_price">
                <div class="d-flex align-items-baseline">
                  <img src="assets/images/rs-icon.png" class="rs_icon" alt="">
                  <span class="price-value"> {{ spaceDetails.flexible_desk_price }}</span>
                  <small class="pl-1"> per seat/month</small>
                </div>
              </ng-container>
            </p>
            <p *ngIf="spaceDetails.flexible_desk_price == null || spaceDetails?.flexible_desk_price == 0">NA</p>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="filters?.type != null">
      <div class="inventory-label">
        <p>{{ filter_type_name }} from</p>
        <p *ngIf="spaceDetails[filter_type_col_name]">
          <span class="price-value">Rs. {{ spaceDetails[filter_type_col_name] }}</span>/{{ filters?.type == 6 ||
          filters?.type == 7 ? "hour" : "month" }}
        </p>
        <p *ngIf="spaceDetails[filter_type_col_name] == null">NA</p>
        <a *ngIf="spaceDetails[filter_type_col_name] == 0" href="/contact-form?space_id={{ spaceDetails.id }}"
          target="_blank" class="cursor-pointer">Enquire Now</a>
      </div>
    </div> -->
      <!-- <div class="inventory-label" >
      <p>Desks from</p>
    </div> -->
      <!-- <p style="font-size: 13px" *ngIf = "spaceDetails.customized_space_price || spaceDetails.flexible_desk_price || spaceDetails.virtual_office_price || spaceDetails.meeting_room_price || spaceDetails.training_room_price || spaceDetails?.privatecabin_price || spaceDetails.desks_price"> -->
      <div class="row mt-2 align-items-end">
        <div class="description col-lg-12">
          <p *ngIf="is_see_more_visible" class="less-content" style="font-size: 14px;color:#141414">
            {{ spaceDetails?.about }}
          </p>
          <a style="font-size: 12px; font-weight: 600; cursor: pointer" *ngIf="is_see_more_visible"
            (click)="toggleSeeMore($event, 0)">See more</a>
          <p class="overflow_wrap" *ngIf="!is_see_more_visible">{{ spaceDetails?.about }}</p>
          <a style="font-size: 12px; font-weight: 600; cursor: pointer" *ngIf="!is_see_more_visible"
            (click)="toggleSeeMore($event, 1)">See less</a>
        </div>
        <div class="col-12 text-right">
          <button class="btn btn-accent" (click)="openInquiryPopUp($event,spaceDetails)">GET QUOTE</button>
        </div>
      </div>
      <!--  <div class="available-resources">
      <div
        [hidden]="!(!is_similar_listing && (spaceDetails.customized_space_price != null || spaceDetails.desks_price != null || spaceDetails.virtual_office_price != null || spaceDetails.meeting_room_price != null || spaceDetails.training_room_price != null))">
        <span>Also Available:&nbsp;</span>
        <span *ngIf="spaceDetails.customized_space_price != null">Customized Space,&nbsp;</span>
        <span *ngIf="spaceDetails.desks_price != null">Dedicated Desk,&nbsp;</span>
        <span *ngIf="spaceDetails.virtual_office_price != null">Virtual Office,&nbsp;</span>
        <span *ngIf="spaceDetails.meeting_room_price != null">Meeting Room&nbsp;</span>
        <span *ngIf="spaceDetails.training_room_price != null">Training Room/Event Space</span>
      </div>
    </div> -->
      <!-- <hr style="margin-top: 5px" *ngIf="!is_similar_listing" />
    <div class="commute-details" *ngIf="!is_similar_listing">
      <div class="commute-details-item">
        <div class="commute-label">
          <i class="material-icons">subway</i>
          <p>Metro</p>
        </div>
        <div class="commute-value">
          <p>{{ spaceDetails.near_by_metro || "NA" }}</p>
          <p>
            {{
            spaceDetails.metro_distance
            ? spaceDetails?.metro_distance + " kms"
            : "NA"
            }}
          </p>
        </div>
      </div>
      <div class="commute-details-item">
        <div class="commute-label">
          <i class="material-icons">train</i>
          <p>Railway</p>
        </div>
        <div class="commute-value">
          <p>{{ spaceDetails.near_by_railway || "NA" }}</p>
          <p>
            {{
            spaceDetails.railway_distance
            ? spaceDetails?.railway_distance + " kms"
            : "NA"
            }}
          </p>
        </div>
      </div>
    </div> -->
    </div>
    <!-- <ul class="trending-address">
        <li>


        </li>
        <li>


        </li>
      </ul> -->

    <!-- <div class="trending-bottom mar-top-15 pad-bot-30">
        <div class="trend-left float-left">
          <span class="round-bg pink"><i class="icofont-hotel"></i></span>
          <p><a href="#">Hotel</a></p>

        </div>
        <div class="trend-right float-right">
          <div class="trend-open"><i class="ion-clock"></i>
            Open <p>till 11.00pm</p>
          </div>
        </div>
      </div> -->
  </div>
</div>