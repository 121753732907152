<!-- <br>
<div class="static-page-container">
  <div>
    <img *ngIf="!isMobile" class="static-header-image" src="assets/images/list_with_us_banner.jpg" width="100%">
    <img *ngIf="isMobile" class="static-header-image" src="assets/images/list_with_us_banner_mobile.jpg" width="100%">
    <a class="large-btn" style="font-size: 20px;padding: 10px;"
      href="https://docs.google.com/forms/d/e/1FAIpQLScCNsY3akGSEpVwWDb-KyhlZ0dYdlvbs1IxiHHXlOZNvPrVJQ/viewform?usp=sf_link">Let's
      Get Started</a>
  </div>
  <h2>List with Us</h2>
  <p>
    FLEXO™ is a one stop shop for flexible workspaces, co-working and shared offices. Listing with us brings your space
    increased visibility and qualified opportunities.
  </p>
  <p>
    From SMEs to large corporates, we get you the best leads, arrange for site visits and stay involved during
    discussions and negotiations.
  </p>
  <p>
    Listing is 100% free and we only charge for successful referrals.
  </p>
  <p><a class="static-page-links" routerLink="/joy-of-giving">List Your Space With Us Today</a>.</p>
  <br>
  <h3>How can I list my property with FLEXO™?</h3>
  <p style="padding-bottom:  30px;">
    Listing is easy, fast and free. Just drop us a note along with your contact details. Our office space expert with
    reach you and guide you through the process.
  </p>
  <h3>What type of properties can I list?</h3>
  <p>Almost all types of commercial properties that can be used as flexible or shared offices are covered by FLEXO™.
  </p>
  <div class="list-with-us-list">
    <ul style="margin: 0 auto">
      <li>
        &#8226; Coworking spaces
      </li>
      <li>
        &#8226; Business centers
      </li>
      <li>
        &#8226; Serviced/managed offices
      </li>
      <li>
        &#8226; Meeting rooms
      </li>
      <li>
        &#8226; Training / Event spaces
      </li>
      <li>
        &#8226; Virtual office solutions
      </li>
      <li>
        &#8226; Cafes/Restaurants/Pubs/Hotels
      </li>
    </ul>
  </div>
  <br>
  <p><a class="static-page-links" routerLink="/joy-of-giving">List Your Space With Us Today</a>.</p>
</div> -->

<div class="container-fluid top_list_img position-relative">
  <!-- <img  class="static-header-image" src="assets/images/list_with_us_banner.webp" width="100%"> -->
  <div class="container d-flex justify-content-end">
    <div class="row justify-content-end w-100">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="list_content d-flex flex-column align-items-start">
          <h1 class="text-white fw-bold"> List Your Space and Grow Your Earnings</h1>
          <ul class="pl-lg-5 pl-md-4 pl-4 list_top text-start">
            <li class="mb-md-3 mb-2"><a href="" class="text-white"> Get Qualified Leads and Bookings </a></li>
            <li class="mb-md-3 mb-2"><a href="" class="text-white"> Maximise Occupancy Levels and Revenue </a></li>
            <li class="mb-md-3 mb-2"><a href="" class="text-white"> Increase Visibility and Reach </a></li>
            <li class=""><a href="" class="text-white"> Turn Unused Space Into Profit</a></li>
          </ul>
          <div class="mt-4 pl-lg-3 pl-md-2 pl-2">
            <a class="large-btn" target="_blank" href="{{hostWebUrl}}/auth/login">Let's
              Get Started</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid choose_share section-padding">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7 col-12">
        <div class="share_img position-relative">
          <img
            src="assets/images/list-us.webp"
            alt="" class="img-fluid w-100">

          <!-- <div class="share_img_txt">
            <h6 class="mb-0">Ben Seidel</h6>
            <p class="text-white">Hosting since 2016</p>
          </div> -->
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-12 mt-md-0 mt-4">
        <div class="share_content p-3">
          <h3 class="section-title mb-0" style="font-size: 24px !important;">Join India's Premier Space Marketplace!</h3>
          <p>Join hundreds of hosts with 1,800+ listings from 20+ cities across India and start monetizing your space today. Whether you have a coworking space, office for lease, meeting room, studio, event venue or activity space, Flexo helps you connect with businesses and professionals actively looking for the perfect workspace.</p>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="container-fluid list_sapce section-padding">
  <div class="container">
    <div class="row justify-content-center">
       <div class="col-12 text-center py-3">
        <h2 class="text-white mb-2">Earn Money as a Flexo Host</h2>
        <p class="text-white custom-lh">Join thousands of hosts renting their space for coworking, meetings, <br> events, podcasts, and video and photo shoots.</p>
         <div class="get_btn text-center mt-4">
           <button type="button" class="btn custum-btn" style="border-radius: 6px;font-size: 15px;">Get Started. List Your Space Today – It’s Free and Easy</button>
         </div>
      </div>
    </div>
  </div>
</div> -->


<div class="container-fluid works_sec section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h2 class="section-title v1">How It Works</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12 mb-lg-0 mb-md-0 mb-4">
        <div class="works_box p-4">
          <div class="works_count mb-2">
            <span>01</span>
          </div>
          <h5 class="mb-0">Sign Up and Create Your Listing</h5>
          <p>Provide key details like location, size, amenities, and usage categories. Upload high-quality photos to
            showcase your space.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 mb-lg-0 mb-md-0 mb-4">
        <div class="works_box p-4">
          <div class="works_count mb-2">
            <span>02</span>
          </div>
          <h5 class="mb-0">Set Your Preferences</h5>
          <p>Choose how you want to rent your space and set your pricing. Offer hourly, daily, or long-term leases based on what works best for your business.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="works_box p-4">
          <div class="works_count mb-2">
            <span>03</span>
          </div>
          <h5 class="mb-0">Start Earning</h5>
          <p>Go live on the platform, receive inquiries and bookings, and effortlessly manage everything from your dashboard.</p>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="container-fluid space_list_sec section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h2 class="section-title v1">What Types of Spaces Can You List?</h2>
        <p>From vibrant coworking spaces, sleek offices to creative studios, every space has a home on Flexo. We bring together diverse spaces for work, creativity, activities, and collaboration—all in one marketplace.</p>
      </div>
    </div>
    <div class="col-12 mt-3">
      <ngx-slick-carousel class="carousel space-list-carousel" #spaceListSlider="slick-carousel"
        [config]="spaceListConfig" responsive="breakpoints">
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Coworking.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Coworking Spaces</h5>
              <p>For long-term and daily rentals.</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Studio-Space.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Studios</h5>
              <p>Perfect for podcasting, photoshoots, and video production.</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Meeting-Room.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Meeting Rooms</h5>
              <p>Equipped for business discussions and training sessions.</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Event-Space.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Event Spaces</h5>
              <p>Ideal for workshops, small gatherings, and networking events.</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Office-Space.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Office Spaces</h5>
              <p>For long-term rentals</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/Activity-Space.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Activity Spaces</h5>
              <p>For fitness studios, dance halls, yoga centers, or any creative space</p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="spce_list_box">
            <div>
              <img
                src="assets/images/And-much-more.webp"
                alt="" class="img-fluid">
            </div>
            <div class="space_list_content text-center p-3">
              <h5 class="mb-0">Unique Spaces</h5>
              <p>Multipurpose spaces that cater to every experience</p>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="container-fluid list_with_flexo section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h2 class="section-title v1">Why List with Flexo?</h2>
      </div>
    </div>
    <div class="row gy-4">
      <div class="col-lg-3 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
        <div class="listFlexo_box p-4">
          <div class="listFlexo_count ">
            <span>01</span>
          </div>
          <div class="mb-5">
            <h5 class="mb-1">Reach the Right Audience</h5>
            <p class="text-white">Tap into a large user base actively searching for spaces like yours.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
        <div class="listFlexo_box p-4">
          <div class="listFlexo_count ">
            <span>02</span>
          </div>
          <div class="mb-5">
            <h5 class="mb-1"> Flexible Options</h5>
            <p class="text-white">List spaces for coworking, meetings, shoots, events, or short-term bookings.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-lg-0 mb-md-0 mb-4">
        <div class="listFlexo_box p-4">
          <div class="listFlexo_count ">
            <span>03</span>
          </div>
          <div class="mb-5">
            <h5 class="mb-1">Maximize Earnings</h5>
            <p class="text-white">Control pricing, availability, and usage types to optimize your revenue.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="listFlexo_box p-4">
          <div class="listFlexo_count ">
            <span>04</span>
          </div>
          <div class="mb-5">
            <h5 class="mb-1">End-to-End Support</h5>
            <p class="text-white">From onboarding to managing bookings, we’ve got you covered.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid succes_story_sec section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h2 class="section-title v1">Host Success Stories</h2>
        <!-- <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure porro eaque assumenda, quidem nemo in aliquam
          perferendis laboriosam aut repudiandae asperiores quod molestias ducimus consectetur dicta eveniet illo
          architecto natus?</p> -->
      </div>
    </div>
    <div class="col-12 mt-3 px-0">
      <ngx-slick-carousel class="carousel success-carousel" #successSlider="slick-carousel" [config]="successConfig"
        responsive="breakpoints">
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="success_box p-4 my-2">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="success_img">
                  <img
                    src="assets/images/Rushad-Ghaswala-WeWork.webp"
                    class="img-fluid " alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-7 col-12 mt-md-0 mt-3">
                <div class="success_content">
                  <div class="quote_mark_iocn mb-4">
                    <img src="assets/images/quote_mark.svg" alt="" class="img-fluid">
                  </div>
                  <p class="success_txt mb-4">Our experience with Flexo has been nothing short of outstanding. The team’s professionalism, efficiency, and dedication have made a significant impact on our operations. What stands out the most is how seamlessly they coordinate with us for site visits, deal closures, and specific client requirements. Their proactive communication and attention to detail ensure that every lead is handled with care, and every opportunity is maximized.The level of collaboration and support we receive from Flexo is unmatched. Their team is always accessible, responsive, and ready to adapt to our needs, making the entire workflow incredibly efficient. I would recommend Flexo to any space operator looking for a reliable, results-driven partner in the industry.
                  </p>
                  <h5 class="mb-1">Rushad Ghaswala</h5>
                  <p>WeWork India, Senior Lead - Sales Transactions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="success_box p-4 my-2">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="success_img">
                  <img
                    src="assets/images/Manas-Eszi.webp"
                    class="img-fluid " alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-7 col-12 mt-md-0 mt-3">
                <div class="success_content">
                  <div class="quote_mark_iocn mb-4">
                    <img src="assets/images/quote_mark.svg" alt="" class="img-fluid">
                  </div>
                  <p class="success_txt mb-4">Working with Flexo has been a great experience. Their team is incredibly professional and helpful throughout the process from site visits to deal closures. Flexo stands out for its transparency and in-depth industry knowledge make them a valuable platform for coworking operators and clients alike. We highly recommend Flexo to other space operators and hosts, as they have helped us achieve many successful closures.</p>
                  <h5 class="mb-1">Manas Dhanghakar</h5>
                  <p>EsziWorkN, Community and Facility Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="success_box p-4 my-2">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="success_img">
                  <img
                    src="assets/images/Amandeep-EFC.webp"
                    class="img-fluid " alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-7 col-12 mt-md-0 mt-3">
                <div class="success_content">
                  <div class="quote_mark_iocn mb-4">
                    <img src="assets/images/quote_mark.svg" alt="" class="img-fluid">
                  </div>
                  <p class="success_txt mb-4">Working with Flexo has been an amazing experience. The team is a great asset, providing valuable support and ensuring a smooth process. Flexo has played a crucial role in helping us close deals at key locations like BKC and Kurla Centrium. What sets them apart is their ability to bring serious clients who are well-informed and decisive, leading to faster closures. Their transparency, professionalism, and industry expertise make them a trusted partner. We highly recommend Flexo to other space operators looking for a reliable and results-driven platform.</p>
                  <h5 class="mb-1">Amandeep Manaktala</h5>
                  <p>EFC Limited, Sr. Sales Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="success_box p-4 my-2">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="success_img">
                  <img
                    src="assets/images/Sudhanshu-Sprint.webp"
                    class="img-fluid " alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-7 col-12 mt-md-0 mt-3">
                <div class="success_content">
                  <div class="quote_mark_iocn mb-4">
                    <img src="assets/images/quote_mark.svg" alt="" class="img-fluid">
                  </div>
                  <p class="success_txt mb-4">Working with Flexo has been an excellent experience. The platform is efficient and user-friendly, making it easy to connect with potential clients and manage inquiries seamlessly. Flexo has significantly helped in generating quality leads and successfully closing deals for our office spaces. The leads we receive through Flexo are well-targeted and relevant, which has streamlined our leasing process and improved our conversion rate. What sets Flexo apart from other platforms is the personalized support and prompt communication we receive from their team. They have been incredibly helpful, always responsive, and proactive in ensuring smooth coordination and timely updates. Their dedication and professionalism make a real difference. I would highly recommend Flexo to other space operators. Their platform, combined with the attentive support from team members, makes them a valuable partner in maximizing occupancy and growing your business.</p>
                  <h5 class="mb-1">Sudhanshu Agrawal</h5>
                  <p>Sprint Coworking, Sales Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="swiper-slide popular-item slide">
          <div class="success_box p-4 my-2">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="success_img">
                  <img
                    src="assets/images/Ganesh-OfficeBing.webp"
                    class="img-fluid " alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-7 col-12 mt-md-0 mt-3">
                <div class="success_content">
                  <div class="quote_mark_iocn mb-4">
                    <img src="assets/images/quote_mark.svg" alt="" class="img-fluid">
                  </div>
                  <p class="success_txt mb-4">Working with Flexo has been an excellent experience. The platform is efficient and user-friendly, making it easy to connect with potential clients and manage inquiries seamlessly. Flexo has significantly helped in generating quality leads and successfully closing deals for our office spaces. The leads we receive through Flexo are well-targeted and relevant, which has streamlined our leasing process and improved our conversion rate. What sets Flexo apart from other platforms is the personalized support and prompt communication we receive from their team. They have been incredibly helpful, always responsive, and proactive in ensuring smooth coordination and timely updates. Their dedication and professionalism make a real difference. I would highly recommend Flexo to other space operators. Their platform, combined with the attentive support from team members, makes them a valuable partner in maximizing occupancy and growing your business. Flexo has proven to be a reliable and results-driven partner, and we highly recommend them to other space operators looking to maximize occupancy and grow their business.</p>
                  <h5 class="mb-1">Ganesh Ghodekar</h5>
                  <p>Office Bing, Area Sales Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>




  <div class="container-fluid earn_sec section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center py-3">
          <h2 class="text-white mb-2">Ready to Earn with Flexo?</h2>
          <div class="get_btn text-center mt-4">
            <button type="button" class="btn custum-btn" style="border-radius: 6px;font-size: 15px;">List Your Space
              Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="container-fluid faq_sec section-padding">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h2 class="section-title v1">Frequently Asked Question</h2>
          <p>Addressing common questions and concerns about listing your space on Flexo</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="accordion" id="accordionExample">
            <div class="card card_acord" *ngFor="let faq of faqs; let i = index">
              <div class="card-header" [id]="'heading' + i">
                <button
                  class="card_btn d-flex align-items-center btn w-100 text-left"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                  [attr.aria-controls]="'collapse' + i"
                  aria-expanded="false"
                >
                  <img src="assets/images/right-icon.webp" class="img-fluid rotate-icon" alt="" />
                  <h5 class="pl-2 mb-0">{{ faq.question }}</h5>
                </button>
              </div>
          
              <div
                [id]="'collapse' + i"
                class="collapse"
                [attr.aria-labelledby]="'heading' + i"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  {{ faq.answer }}
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>