<div class="privacy-content">
    <!--section -->
    <section class="parallax-section" data-scrollax-parent="true">
      <div class="bg1 par-elem " data-scrollax="properties: { translateY: '30%' }"
        style="background-color: gray; transform: translateZ(0px) translateY(-4.22535%);">
      </div>
      <!-- <img class="bg1 par-elem " src="assets/images/Contact.jpg" alt=""
        style="transform: translateZ(0px) translateY(-4.22535%);"> -->
      <div class="privacy-container">
        <div class="col-md-8 offset-md-2 text-center">
          <h2 class="section-title v1" style="color: white;
          font-size: 28px;
          font-weight: 600;
          margin-bottom: 0px;
          padding-bottom: 0px;">REFUND POLICY</h2>
        </div>
      </div>
    </section>
    <section class="gray-section" id="sec1">
      <div class="privacy-container">
        <div class="row">
          <div class="col-md-12">
            <div class="list-single-main-wrapper fl-wrap" id="sec2">
              <!-- article> -->
              <article>
                <div style="text-align: left;">
                  <div class="list-single-main-item-title-1 fl-wrap terms">
                    <h3>
                      For Hourly Bookings & Day Passes:
                    </h3>
                  </div>
                  <ul>
                    <li style="list-style: disc !important;"> Refunds and cancellations are subject to the cancellation policy of the space booked.</li>
                    <li style="list-style: disc !important;">Some spaces may offer full or partial refunds, while others may be non-refundable.</li>
                    <li style="list-style: disc !important;">In case of a no-show, refunds will not be issued.</li>
                  </ul>
                  <div class="list-single-main-item-title-1 fl-wrap terms">
                      <h3> For Subscription & Credit Packages (Hosts):</h3>
                  </div>
                  <ul>
                    <li style="list-style: disc !important;">Non-refundable once purchased.</li>
                    <li style="list-style: disc !important;">Unused credits remain valid for the duration specified at the time of purchase.</li>
                  </ul>
                  <div class="list-single-main-item-title-1 fl-wrap terms">
                    <h3>
                      General Terms:
                    </h3>
                  </div>
                  <ul>
                    <li style="list-style: disc !important;">Refunds (if applicable) are usually processed within 7 to 10 business days.</li>
                    <li style="list-style: disc !important;">Any disputes must be raised within 48 hours of the booking date.</li>
                    
                    
                  </ul>
                  <p class="mt-5 " style="font-weight: 500;">For any assistance, contact support@flexospaces.com</p>
                  <p class="mt-5 " style="color: #000; font-weight: 500;">© Flexo Proptech Pvt Ltd. All rights reserved.</p>
                </div>
              </article>
            </div>
          </div>
          <!--box-widget-wrap -->
        </div>
      </div>
    </section>
  </div>