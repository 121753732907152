<br>
<div class="static-page-container">
  <div>
    <img *ngIf="!isMobile" class="static-header-image" src="assets/images/joy_of_giving_banner_pc.jpg" width="100%">
    <img *ngIf="isMobile" class="static-header-image" src="assets/images/joy_of_giving_banner_mobile.jpg" width="100%">
  </div>
  <h2>Joy of Giving</h2>
  <p>
    Real happiness lies in making others happy. At Flexo, we strive to spread joy by giving back to society and to
    Mother Nature.</p>
  <p style="padding-bottom: 30px;">
    As part of our Joy Of Giving initiative, we plant a fruit tree in your name for every long-term desk that you book
    through us.
  </p>
  <!-- <h3>Founder's Journey</h3>
  <p>
    The trees that are planted bear fruit for about 20-25 years, benefiting farmers and rural communities for decades to
    come. All the trees we plant are geo-tagged, visible on google maps and can be tracked online.
  </p>
  <p>
    To change any of the text in this document, just click on the block of text you want to update! The formatting has
    already been programmed for ease of formatting.
  </p> -->
  <!-- <h3>How we Give Back</h3> -->
  <br>
  <div class="static-page-flex-container">
    <img src="assets/images/joy_of_giving_helping_farmers.jpg" alt="">
    <div class="mt-md-0 mt-4">
      <p class="static-page-sub-heading">Helping Farmers and Rural Communities</p><br>
      <p>
        The trees that are planted bear fruit for about 20-25 years, benefiting farmers and rural communities for
        decades to come. All the trees we plant are geo-tagged, visible on google maps and can be tracked online.
      </p>
    </div>
  </div><br>
  <div class="static-page-flex-container-reverse">
    <div class="mt-md-0 mt-4">
      <p class="static-page-sub-heading">Towards Sustainability</p><br>
      <p>
        While we can never give back what we take from Nature, we strive to make our planet more sustainable by
        contributing in a small but meaningful way. Planting trees helps prevent soil erosion, increases land fertility
        and mitigates global warming.
      </p>
    </div>
    <img src="assets/images/joy_of_giving_planting.jpg" alt="">
  </div><br>
  <div class="static-page-flex-container">
    <img src="assets/images/joy_of_giving_environment.jpg" alt="">
    <div class="mt-md-0 mt-4">
      <p class="static-page-sub-heading">Environment as a Stakeholder
      </p><br>
      <p>
        This is a small action towards helping create a world that recognizes the environment as a stakeholder, a world
        that is filled with generosity and a world that gives back as it evolves. We wish to do more with your support.
        Let's work towards creating a better place for all of us to live in because there's no greater joy than the one
        there is in giving.
      </p>
    </div>
  </div>
</div>