<div class="sdle-vist-all">
    <div class="header-div">
        <h4 class="login-text" *ngIf="!isUpdating">
            Schedule Visit
        </h4>
        <h4 class="login-text" *ngIf="isUpdating">
            Update Schedule Visit
        </h4>
        <mat-icon style="cursor: pointer;" class="cross material-icons" (click)="closeDialog(null)">
            close
        </mat-icon>
    </div>
    <div class="multi-step-header">
        <p> Schedule a personalised tour with one of our experts </p>
    </div>
    <div class="sdle-vist-bdy">     
        <div class="vst-sdle-cont" >
            <ng-container>          
                <h2>When would you like to visit?</h2>
                <form [formGroup]="scheduleVisitForm">
                    <div class="form-field-div">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Preferred view Date </mat-label>
                            <input (dateChange)="onDateChange($event)" matInput [min]="date" (click)="picker.open()" [matDatepicker]="picker" formControlName="visitDate" style="font-size: large;">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-error *ngIf="scheduleVisitForm['controls']['visitDate']?.errors?.required">Visit Date Time
                                is required.</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-field-div">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Preferred view Time</mat-label>
                            <mat-select formControlName="visitTime">
                                <mat-option *ngFor="let startTime of startTimes" [disabled]="isTimeDisabled(startTime.value)" [value]="startTime.value">
                                    {{startTime.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="scheduleVisitForm['controls']['visitTime']?.errors?.required">visitTime is
                                required.</mat-error>
                        </mat-form-field>
                    </div>
    
                </form>
          
                <button class="btn form-btn-submit" (click)="onSubmitScheduleVisit()">
                    Submit
                </button>
            </ng-container>
        </div>
        <div class="step-term-condition text-center" style="padding: 20px; padding-top: 0px;">
            <!-- <p>By submitting your application and subscribing to our services, you agree to our <a href="">Global Terms of Use.</a></p> -->
            <hr>
            <p style="font-size: 11px;">After you submit a workspace enquiry to us, we may share your details with workspace providers, who may contact you to follow up on your enquiry." Please read our <a href="" style="font-size: 11px;"> Privacy Policy </a> for details of how we process the information.</p>
        </div>
    </div>    
</div>