import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-_404',
  templateUrl: './_404.component.html',
  styleUrls: ['./_404.component.scss']
})
export class _404Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
